import axios from "axios";
import { makeAutoObservable } from "mobx";

import { store } from "../store";

export default class AdminFileToIRDStore {
  isShowFileResult: {
    isShow: boolean;
    isProcessing: boolean;
    total: number;
    numberOfSuccess: number;
    numberOfFail: number;
    numberOfNotFiled: number;
    errorMessageList?: string[];
  } = {
    isShow: false,
    total: 0,
    numberOfSuccess: 0,
    numberOfFail: 0,
    numberOfNotFiled: 0,
    isProcessing: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsShowFileResult = (
    tempIsShow: boolean,
    tempIsProcessing: boolean,
    tempTotal: number,
    tempTotalSuccess: number,
    tempTotalFail: number,
    tempTotalNotFiled: number,
    errorMessageList?: string[]
  ) => {
    this.isShowFileResult = {
      isShow: tempIsShow,
      isProcessing: tempIsProcessing,
      total: tempTotal,
      numberOfSuccess: tempTotalSuccess,
      numberOfFail: tempTotalFail,
      numberOfNotFiled: tempTotalNotFiled,
      errorMessageList: errorMessageList,
    };
  };

  FileToIrdHandler = async (whichPayrunIdToFile: string) => {
    var isFiled = false;
    var isFileSuccess = false;
    var errorMessage = "";

    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      PayrunIdToFile: whichPayrunIdToFile,
      IsSendEmailOnlyOnSuccess: false,
    };
    let requestHeader = await store.authStore.getRequestHeader();

    await axios
      .post("/Admin/FileToIRD", bodyParameters, requestHeader)
      .then((response) => {
        store.loadingStore.setIsLoading(false);
        isFiled = response.data.item1;
        isFileSuccess = response.data.item2;
        errorMessage = response.data.item3;
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
          store.loadingStore.setIsLoading(false);
          isFiled = false;
          isFileSuccess = false;
        }
      });

    if (isFiled) {
      if (isFileSuccess) {
        this.isShowFileResult.numberOfSuccess =
          this.isShowFileResult.numberOfSuccess + 1;
      } else {
        this.isShowFileResult.numberOfFail =
          this.isShowFileResult.numberOfFail + 1;
      }
    } else {
      this.isShowFileResult.numberOfNotFiled =
        this.isShowFileResult.numberOfNotFiled + 1;
    }

    if (errorMessage !== "") {
      if (this.isShowFileResult.errorMessageList === undefined) {
        this.isShowFileResult.errorMessageList = [errorMessage];
      } else {
        this.isShowFileResult.errorMessageList.push(errorMessage);
      }
    }
  };
}
