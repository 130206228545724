import { observer } from "mobx-react-lite";
import { useStore } from "../../mobxStores/store";
import AuthLockOut from "./AuthLockOut";
import LoginComponent from "./LoginComponent";

const AuthComponent = () => {
  const { authStore } = useStore();

  return (
    <>
      {authStore.logginSetting.isShow === true && (
        <LoginComponent
          isShow={authStore.logginSetting.isShow}
          isLogin={authStore.logginSetting.isLogin}
          isGetStarted={authStore.logginSetting.isGetStarted}
        />
      )}

      {authStore.isShowLogOutScreen === true && <AuthLockOut />}
    </>
  );
};

export default observer(AuthComponent);
