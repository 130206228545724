import axios from "axios";
import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "./commonComponent/LoadingComponent";
import "@fontsource/inter";
import "@fontsource/exo";
import { useStore } from "./mobxStores/store";
import { ViewEmployeeEnum } from "./afterLogin/Employees/EditEmployee/ViewEmployeeEnum";
import { observer } from "mobx-react-lite";
import { IsCellPhone } from "./utility/ResponsiveTool";
import { Button, Divider, Tag } from "antd";
import { AdminLeftMenu } from "./adminLogin/Setting/LeftNavBarAdmin";

axios.defaults.baseURL = process.env.REACT_APP_BASE_APIURL;

const HomePage = React.lazy(
  () => import("./beforeLogin/1_LandingPage/HomePage")
);
const Pricing = React.lazy(() => import("./beforeLogin/4_Pricing/Pricing"));
const Features = React.lazy(() => import("./beforeLogin/3_Feature/Features"));
const WhyUs = React.lazy(() => import("./beforeLogin/2_WhyUs/WhyUs"));

const NotFound404 = React.lazy(() => import("./commonComponent/NotFound404"));
const AddEmployee = React.lazy(
  () => import("./afterLogin/Employees/AddEmployee/AddEmployee")
);
const ViewEmployee = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewEmployee")
);
const ViewEmployeeBasicInfo = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewEmployeeBasicInfo")
);

const ViewEmployeeEmployment = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewEmployeeEmployment")
);
const ViewEmployeeTax = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewEmployeeTax")
);
const ViewEmployeeLeave = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewEmployeeLeave")
);

const ViewEmployeeLeaveLeaveBreakdown = React.lazy(
  () =>
    import("./afterLogin/Employees/EditEmployee/LeaveBreakdown/LeaveBreakdown")
);

const IrdoAuth = React.lazy(() => import("./afterLogin/Dashboard/IrdoAuth"));

const ViewEmployeeExtraPayDeduction = React.lazy(
  () =>
    import("./afterLogin/Employees/EditEmployee/ViewEmployeeExtraPayDeduction")
);
const ViewEmployeeExtraPayDeductionHistory = React.lazy(
  () =>
    import(
      "./afterLogin/Employees/ExtraPayDeduction/ViewExtraPayDeductionHistory"
    )
);
const ViewPayslipsList = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewPayslipsList")
);

const ViewPayslipDetail = React.lazy(
  () => import("./afterLogin/Employees/EditEmployee/ViewPayslipDetail")
);

const AddPayroll = React.lazy(
  () => import("./afterLogin/Payroll/AddPayroll/AddPayroll")
);

const ProfileSupport = React.lazy(
  () => import("./afterLogin/Profile/ProfileSupport")
);

const PayrollHistory = React.lazy(
  () => import("./afterLogin/Dashboard/DashboardPayrollHistory")
);

const PayrollInvoice = React.lazy(
  () => import("./afterLogin/Dashboard/DashboardPayrollInvoice")
);

const ConnectToIRD = React.lazy(() => import("./afterLogin/ConnectToIRD"));

const AdminEntryPoint = React.lazy(
  () => import("./adminLogin/AdminEntryPoint")
);

const ClientEntryPoint = React.lazy(
  () => import("./afterLogin/ClientEntryPoint")
);

function App() {
  const { authStore } = useStore();

  const location = useLocation();

  useEffect(() => {
    authStore.checkLoggedUser(location);

    return () => {};
  }, []);

  var environment = "";
  if (process.env.REACT_APP_BASE_URL !== undefined) {
    environment = process.env.REACT_APP_BASE_URL;
  }
  const isCellPhone = IsCellPhone();

  const history = useNavigate();

  return (
    <div className="App">
      <header
        className="App-header"
        style={{ fontFamily: "Inter", lineHeight: "116%" }}
      >
        {/* <Route path={"/(.+)"} children={() => {}} /> */}

        {environment !== "production" && (
          <Divider>
            <Tag color="red">Staging</Tag>
          </Divider>
          // <Notification
          //   title={"Staging"}
          //   description={"You are in staging mode"}
          //   style={{
          //     borderBottom: "0.5px solid #262626",
          //   }}
          //   closable={false}
          //   backgroundColor={"red"}
          // />
        )}

        {authStore.isAdminAccessClient === true && (
          <Divider>
            <Tag color="red">
              Access client{" "}
              <Button
                style={{ height: "fit-content", fontSize: 12 }}
                onClick={() => {
                  history("/admin/" + AdminLeftMenu.AllClient);
                  window.location.reload();
                }}
                type="link"
              >
                Go admin page
              </Button>
            </Tag>
          </Divider>

          // <Notification
          //   title={"You are accessing client account"}
          //   description={"Go back to admin home"}
          //   style={{
          //     borderBottom: "0.5px solid #262626",
          //   }}
          //   closable={false}
          //   backgroundColor={"red"}
          //   onClick={() => {
          //     history("/admin/" + AdminLeftMenu.AllClient);
          //     window.location.reload();
          //   }}
          // />
        )}

        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<HomePage />}
              </Suspense>
            }
            key={location.key}
          />
          <Route
            path="/Pricing"
            element={
              <Suspense fallback={<LoadingComponent />}>{<Pricing />}</Suspense>
            }
          />

          <Route
            path="/Dashboard/Profile/Support"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ProfileSupport />}
              </Suspense>
            }
          />

          <Route
            path="/Why-us"
            element={
              <Suspense fallback={<LoadingComponent />}>{<WhyUs />}</Suspense>
            }
          />

          <Route
            path="/Features"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<Features />}
              </Suspense>
            }
          />

          <Route
            path="/Dashboard/Employees/AddEmployee/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<AddEmployee />}
              </Suspense>
            }
          />
          <Route
            path="/Dashboard/Employees/ViewEmployee/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployee />}
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.BasicInfo}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeBasicInfo />}
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.Employment}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeEmployment />}
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.TaxKiwisaver}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeTax />}
              </Suspense>
            }
          />

          <Route
            path={`/irdoauth`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<IrdoAuth />}
              </Suspense>
            }
          />

          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.Leave}/LeaveBreakdown/:whichLeaveBreakdown`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeLeaveLeaveBreakdown />}
              </Suspense>
            }
          />

          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.Leave}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeLeave />}
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.ExtraPayDeduction}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewEmployeeExtraPayDeduction />}
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.ExtraPayDeduction}/:id/History`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                <ViewEmployeeExtraPayDeductionHistory />
              </Suspense>
            }
          />
          <Route
            path={`/Dashboard/Employees/ViewEmployee/${ViewEmployeeEnum.Payslips}/:id`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewPayslipsList />}
              </Suspense>
            }
          />

          <Route
            path={`/ViewPayslips/:employeeId/:payslipId/:isShowBackArrow`}
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ViewPayslipDetail />}
              </Suspense>
            }
          />

          <Route
            path="/Dashboard/Payroll/History/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<PayrollHistory />}
              </Suspense>
            }
          />
          <Route
            path="/dashboard/Payroll/Invoice"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<PayrollInvoice />}
              </Suspense>
            }
          />

          <Route
            path="/Dashboard/Payroll/AddPayroll/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<AddPayroll />}
              </Suspense>
            }
          />
          <Route
            path="/Dashboard/ConnectToIRD/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ConnectToIRD />}
              </Suspense>
            }
          />
          <Route
            path="/Dashboard/:position/:id"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<ClientEntryPoint />}
              </Suspense>
            }
          />
          <Route
            path="/Admin/:position/:position2?"
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<AdminEntryPoint />}
              </Suspense>
            }
          />

          <Route
            element={
              <Suspense fallback={<LoadingComponent />}>
                {<NotFound404 />}
              </Suspense>
            }
          />
        </Routes>
      </header>
    </div>
  );
}

export default observer(App);
