import React from "react";

const FlashLogoVertical = React.lazy(() => import("./FlashLogoVertical"));
const FlashLogoHorizontal = React.lazy(() => import("./FlashLogoHorizontal"));

const GetLogo = (
  width?: string,
  height?: string,
  isVertical?: boolean,
  isHorizontal?: boolean
) => {
  let whichLogo: string = "";

  if (process.env.REACT_APP_BASE_Logo !== undefined) {
    whichLogo = process.env.REACT_APP_BASE_Logo;
  }

  if (whichLogo.toLowerCase() === "flash") {
    if (isVertical === true) {
      return <FlashLogoVertical width={width} height={height} />;
    } else if (isHorizontal === true) {
      return <FlashLogoHorizontal />;
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }
};

export default GetLogo;
