import { makeAutoObservable } from "mobx";

export default class CopyStore {
  onCopy: {
    isCopied: boolean;
    copiedName: string;
  } = { isCopied: false, copiedName: "" };

  constructor() {
    makeAutoObservable(this);
  }

  setOnCopy = async (isCopied: boolean, copyName: string) => {
    // const clipBoardData = await navigator.clipboard.readText();

    this.onCopy = { isCopied: isCopied, copiedName: copyName };
  };
}
