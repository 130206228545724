import { Button, Tag, Tooltip } from "antd";
import React from "react";
import GetFont from "../../Media/fontFamily/GetFont";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { IsCellPhone } from "../../utility/ResponsiveTool";
import { Popover } from "antd-mobile";

interface ITitle {
  text: string;
  style?: React.CSSProperties;
}
export const Title = ({ text, style }: ITitle) => {
  return (
    <div
      style={{
        fontFamily: "Inter",
        fontSize: IsCellPhone() ? 36 : 48,
        fontWeight: 800,
        color: "#262626",
        lineHeight: "150%",
        ...style,
      }}
    >
      {text}
    </div>
  );
};

interface IDescription {
  text: string;
  style?: React.CSSProperties;
}
export const Description = ({ text, style }: IDescription) => {
  return (
    <div
      style={{
        fontFamily: GetFont("description"),
        fontSize: 24,
        lineHeight: "150%",
        fontWeight: 700,
        color: "#262626",
        ...style,
      }}
    >
      {text}
    </div>
  );
};

interface IMainActionButton {
  text: string;
  onClickHandler: Function;
  isHTMLtypeSubmit?: boolean;
  style?: React.CSSProperties;
  isDisable?: boolean;
  isLoading?: boolean;
  icon?: JSX.Element;
  buttonId?: string;
}

export const OvalActionButton = ({
  text,
  onClickHandler,
  isHTMLtypeSubmit,
  style,
  isDisable,
  isLoading,
  icon,
  buttonId,
}: IMainActionButton) => {
  const isCellPhone = IsCellPhone();

  return (
    <Button
      id={buttonId}
      type="primary"
      htmlType={isHTMLtypeSubmit === true ? "submit" : undefined}
      style={{
        width: isCellPhone ? "fit-content" : 152,
        height: 48,
        border: 0,
        boxSizing: "border-box",
        borderRadius: 28,
        fontSize: 16,
        lineHeight: "150%",
        color: "#EBF0FF",
        fontWeight: 800,
        background: isDisable === true ? "#9CAFF1" : "#0039FF",
        textShadow: "none",
        boxShadow: "none",
        marginRight: 12,
        ...style,
      }}
      onClick={() => {
        if (isDisable !== true) {
          onClickHandler();
        }
      }}
      disabled={isDisable}
      loading={isLoading}
    >
      {icon !== undefined && icon}
      {text}
    </Button>
  );
};

interface ISubActionButton {
  text: string | JSX.Element;
  onClickHandler: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  isRemoveUnderLine?: boolean;
  isDisable?: boolean;
  toolTips?: { name: string; description: string };
  isLoading1?: boolean;
}

export const LineActionButton = ({
  text,
  onClickHandler,
  style,
  isRemoveUnderLine,
  isDisable,
  toolTips,
  isLoading1,
}: ISubActionButton) => {
  return (
    <>
      <Button
        type={"link"}
        style={{
          height: 48,
          fontSize: 14,
          lineHeight: "150%",
          color: isDisable ? undefined : "#262626",
          fontWeight: 700,
          paddingLeft: 12,
          paddingRight: 12,
          ...style,
        }}
        onClick={onClickHandler}
        disabled={isDisable}
        loading={isLoading1}
      >
        <span
          style={{
            textDecoration: isRemoveUnderLine ? undefined : "underline",
            textUnderlineOffset: 6,
          }}
        >
          {text}
        </span>
      </Button>
      {toolTips !== undefined && (
        <Popover content={toolTips.description} mode="dark" trigger="click">
          <span
            style={{
              height: 48,
              fontSize: 14,
              lineHeight: "150%",
              color: "#8C8C8C",
            }}
          >
            {toolTips.name}
          </span>
        </Popover>
      )}
    </>
  );
};

export const InputBoxFontStyle: React.CSSProperties = {
  fontSize: 24,
  lineHeight: "28px",
  fontFamily: GetFont("description"),
  width: "100%",
};

export const BorderLessStyle: React.CSSProperties = {
  border: 0,
  borderBottom: "1px solid #595959",
  boxSizing: "border-box",
  height: 52,
  fontSize: 24,
  lineHeight: "116%",
  fontFamily: GetFont("description"),
  padding: 0,
  width: "100%",
};

interface ICommonTag {
  color: string;
  text: string;
  helperText?: string;
  marginLeft?: number;
}
export const CommonTag = ({
  color,
  text,
  helperText,
  marginLeft,
}: ICommonTag): JSX.Element => {
  return (
    <Tag
      style={{
        background: color,
        borderRadius: 4,
        border: 0,
        fontSize: 12,
        fontWeight: 700,
        color: color === "#FC642D" ? "#FFFFFF" : "#262626",
        lineHeight: "150%",
        padding: "4px 8px",
        marginLeft: marginLeft !== undefined ? marginLeft : 12,
        height: "fit-content",
      }}
    >
      <span>{text}</span>
      {helperText !== undefined && (
        <Popover content={helperText} mode="dark" trigger="click">
          <span
            style={{
              marginLeft: 8,
              // height: 48,
              fontSize: 14,
              lineHeight: "150%",
              color: "#8C8C8C",
            }}
          >
            <QuestionCircleOutlined />
          </span>
        </Popover>
      )}
    </Tag>
  );
};
