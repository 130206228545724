export const EmploymentTypeEnum = {
  FullTime: { name: "Full time", number: 1 },
  PartTime: { name: "Part time", number: 2 },
  Contractor: { name: "Contractor", number: 3 },
  Casual: { name: "Casual", number: 4 },
  FixedTerm: { name: "Fixed term", number: 5 },
};

export const PaymentTypeEnum = {
  HourlyRate: { name: "Hourly rate", number: 1 },
  AnnualSalary: { name: "Annual salary", number: 2 },
};

export const PayFrequencyEnum = {
  Weekly: { name: "Weekly", number: 1 },
  Fortnightly: { name: "Fortnightly", number: 2 },
  FourWeekly: { name: "Four weekly", number: 4 },
  Monthly: { name: "Monthly", number: 5 },
};

export const TaxCodeEnum = {
  WT: "WT",
  ND: "ND",
  M: "M",
  MSL: "MSL",
  ME: "ME",
  MESL: "MESL",
  SB: "SB",
  SBSL: "SBSL",
  S: "S",
  SSL: "SSL",
  SH: "SH",
  SHSL: "SHSL",
  ST: "ST",
  STSL: "STSL",
  SA: "SA",
  SASL: "SASL",
  CAE: "CAE",
  EDW: "EDW",
  NSW: "NSW",
  STC: "STC",
};

export const TaxCodeList: string[] = [
  TaxCodeEnum.WT,
  TaxCodeEnum.ND,
  TaxCodeEnum.M,
  TaxCodeEnum.MSL,
  TaxCodeEnum.ME,
  TaxCodeEnum.MESL,
  TaxCodeEnum.SB,
  TaxCodeEnum.SBSL,
  TaxCodeEnum.S,
  TaxCodeEnum.SSL,
  TaxCodeEnum.SH,
  TaxCodeEnum.SHSL,
  TaxCodeEnum.ST,
  TaxCodeEnum.STSL,
  TaxCodeEnum.SA,
  TaxCodeEnum.SASL,
  TaxCodeEnum.CAE,
  TaxCodeEnum.EDW,
  TaxCodeEnum.NSW,
  TaxCodeEnum.STC,
];

export const PayTypeCategoryNumber = {
  Normalpay: { min: 1, max: 9 },
  Leave: { min: 10, max: 19 },
  ExtraPay: { min: 30, max: 39 },
  Deduction: { min: 50, max: 59 },
  TaxCalculation: { min: 70, max: 79 },
  EmployerKiwisaver: { min: 80, max: 89 },
  NetPayToEmployee: { min: 90, max: 99 },
};

export const PayTypeEnum = {
  //normal pay
  NormalHourlyRatePay: 1,
  GSTPayment: 2,
  HolidayPayPayAsYouGo: 3,

  // leave
  AnnualLeave: 11,
  Sickleave: 12,
  AlternativeLeaveTaken: 13,
  FamilyViolence: 14,
  ParentalLeaveTaken: 15,
  BereavementLeave: 16,
  PublicHoliday: 17,

  // extra pay
  Reimbursement: 30,
  TimeAndHalf: 31,
  DoubleTime: 32,
  Bonus: 33,
  Commission: 34,
  BackPay: 35,
  RetirementPayment: 36,
  RedundancyPayment: 37,
  ExtraPayOther: 38,

  // final pay
  AvailableLeave_FinalPayout: 40,
  AlternativeLeave_FinalPayout: 41,
  Accrual_FinalPayout: 42,

  //extra deduction
  WorkandIncomeDebt: 50,
  CourtFine: 51,
  ChildSupport: 52,
  IRDArrears: 53,
  SLCIRRate: 54,
  SLBORAmount: 55,
  DeductionOther: 56,

  //tax calculation
  PayeDeduction: 71,
  AccDeduction: 72,
  StudentLoan: 73,
  EmployeeKiwisaverDeduction: 74,

  //Employer Kiwisaver,
  EmployerKiwisaverContribution: 80,
  EmployerKiwisaverContribution_ESCT: 81,
  EmployerKiwisaverContribution_Net: 82,

  //netPay to employee
  NetPayToEmployee: 90,
};

export const IsPayType_Pay = (payTypeId: number): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.NormalHourlyRatePay ||
    payTypeId === PayTypeEnum.GSTPayment ||
    payTypeId === PayTypeEnum.HolidayPayPayAsYouGo ||
    payTypeId === PayTypeEnum.AnnualLeave ||
    payTypeId === PayTypeEnum.Sickleave ||
    payTypeId === PayTypeEnum.AlternativeLeaveTaken ||
    payTypeId === PayTypeEnum.FamilyViolence ||
    payTypeId === PayTypeEnum.ParentalLeaveTaken ||
    payTypeId === PayTypeEnum.PublicHoliday ||
    payTypeId === PayTypeEnum.BereavementLeave ||
    payTypeId === PayTypeEnum.Reimbursement ||
    payTypeId === PayTypeEnum.TimeAndHalf ||
    payTypeId === PayTypeEnum.DoubleTime ||
    payTypeId === PayTypeEnum.Bonus ||
    payTypeId === PayTypeEnum.Commission ||
    payTypeId === PayTypeEnum.BackPay ||
    payTypeId === PayTypeEnum.RetirementPayment ||
    payTypeId === PayTypeEnum.RedundancyPayment ||
    payTypeId === PayTypeEnum.ExtraPayOther ||
    payTypeId === PayTypeEnum.Accrual_FinalPayout ||
    payTypeId === PayTypeEnum.AvailableLeave_FinalPayout ||
    payTypeId === PayTypeEnum.AlternativeLeave_FinalPayout ||
    payTypeId === PayTypeEnum.EmployerKiwisaverContribution ||
    payTypeId === PayTypeEnum.EmployerKiwisaverContribution_ESCT ||
    payTypeId === PayTypeEnum.EmployerKiwisaverContribution_Net
  ) {
    result = true;
  }
  return result;
};

export const IsExtraPay = (payTypeId: number): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.Reimbursement ||
    payTypeId === PayTypeEnum.TimeAndHalf ||
    payTypeId === PayTypeEnum.DoubleTime ||
    payTypeId === PayTypeEnum.Bonus ||
    payTypeId === PayTypeEnum.Commission ||
    payTypeId === PayTypeEnum.BackPay ||
    payTypeId === PayTypeEnum.RetirementPayment ||
    payTypeId === PayTypeEnum.RedundancyPayment ||
    payTypeId === PayTypeEnum.ExtraPayOther
  ) {
    result = true;
  }
  return result;
};

export const IsExtraDeduction = (payTypeId: number): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.WorkandIncomeDebt ||
    payTypeId === PayTypeEnum.CourtFine ||
    payTypeId === PayTypeEnum.ChildSupport ||
    payTypeId === PayTypeEnum.IRDArrears ||
    payTypeId === PayTypeEnum.SLCIRRate ||
    payTypeId === PayTypeEnum.SLBORAmount ||
    payTypeId === PayTypeEnum.DeductionOther
  ) {
    result = true;
  }
  return result;
};

//  //Employer Kiwisaver,
//  : 80,
//  EmployerKiwisaverTax: 81,
//  EmployerKiwisaverNet: 82,

//  //netPay to employee
//  NetPayToEmployee: 90

export const IsPayType_Leave = (
  payTypeId: number,
  isShowDaysTaken?: boolean
): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.AnnualLeave ||
    payTypeId === PayTypeEnum.Sickleave ||
    payTypeId === PayTypeEnum.AlternativeLeaveTaken ||
    payTypeId === PayTypeEnum.FamilyViolence ||
    payTypeId === PayTypeEnum.ParentalLeaveTaken ||
    payTypeId === PayTypeEnum.PublicHoliday ||
    payTypeId === PayTypeEnum.BereavementLeave
  ) {
    result = true;
  }

  if (isShowDaysTaken === true) {
    result = false;
    if (
      payTypeId === PayTypeEnum.Sickleave ||
      payTypeId === PayTypeEnum.AlternativeLeaveTaken ||
      payTypeId === PayTypeEnum.FamilyViolence ||
      payTypeId === PayTypeEnum.ParentalLeaveTaken ||
      payTypeId === PayTypeEnum.PublicHoliday ||
      payTypeId === PayTypeEnum.BereavementLeave
    ) {
      result = true;
    }
  }
  return result;
};

export const IsPayType_TimeandHalf = (payTypeId: number): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.TimeAndHalf ||
    payTypeId === PayTypeEnum.DoubleTime
  ) {
    result = true;
  }
  return result;
};

export const IsPayType_with_hour = (payTypeId: number): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.NormalHourlyRatePay ||
    payTypeId === PayTypeEnum.AnnualLeave ||
    payTypeId === PayTypeEnum.Sickleave ||
    payTypeId === PayTypeEnum.AlternativeLeaveTaken ||
    payTypeId === PayTypeEnum.FamilyViolence ||
    payTypeId === PayTypeEnum.ParentalLeaveTaken ||
    payTypeId === PayTypeEnum.PublicHoliday ||
    payTypeId === PayTypeEnum.BereavementLeave ||
    payTypeId === PayTypeEnum.TimeAndHalf ||
    payTypeId === PayTypeEnum.DoubleTime
  ) {
    result = true;
  }
  return result;
};

export const IsPayType_Deduction = (
  payTypeId: number,
  isIncludingNetPayToEmployee: boolean
): boolean => {
  let result: boolean = false;
  if (
    payTypeId === PayTypeEnum.WorkandIncomeDebt ||
    payTypeId === PayTypeEnum.CourtFine ||
    payTypeId === PayTypeEnum.ChildSupport ||
    payTypeId === PayTypeEnum.IRDArrears ||
    payTypeId === PayTypeEnum.SLCIRRate ||
    payTypeId === PayTypeEnum.SLBORAmount ||
    payTypeId === PayTypeEnum.DeductionOther ||
    payTypeId === PayTypeEnum.PayeDeduction ||
    payTypeId === PayTypeEnum.AccDeduction ||
    payTypeId === PayTypeEnum.StudentLoan ||
    payTypeId === PayTypeEnum.EmployeeKiwisaverDeduction
  ) {
    result = true;
  }

  if (isIncludingNetPayToEmployee === true) {
    if (payTypeId === PayTypeEnum.NetPayToEmployee) {
      result = true;
    }
  }
  return result;
};

export const GetPayrunStatusName = (payStatus: number) => {
  switch (payStatus) {
    case PayrunStatusEnum.Draft:
      return "Draft";
    case PayrunStatusEnum.Approved:
      return "Approved";
    case PayrunStatusEnum.PaidAuto:
      return "Paid Auto";
    case PayrunStatusEnum.PaidManual:
      return "Paid Manual";
    case PayrunStatusEnum.OkToPayEmployee:
      return "Ok to pay employee";
    // case PayrunStatusEnum.OKToFileToIRD:
    //   return "Ok to file to IRD";
    // case PayrunStatusEnum.OkToPayPAYEHolding:
    //   return "Ok to pay holding";
    // case PayrunStatusEnum.OkToPayServiceFee:
    //   return "Ok to pay service fee";
    case PayrunStatusEnum.OkToPayIRDFromHolding:
      return "Ok to pay IRD from holding";
    case PayrunStatusEnum.AllPaymentCleared:
      return "All payment cleared to IRD";
    default:
      return "No name found";
  }
};
export const GetPayTypeName = (payTypeId: number) => {
  switch (payTypeId) {
    //normal pay
    case 1:
      return "Normal hourly rate pay";
    case 2:
      return "GST payment";
    case 3:
      return "Holiday pay - pay as you go";
    // leave
    case 11:
      return "Annual leave";
    case 12:
      return "Sick leave";
    case 13:
      return "Alternative leave taken";
    case 14:
      return "Family violence";
    case 15:
      return "Parental leave taken";
    case 16:
      return "Bereavement leave";
    case 17:
      return "Public holiday";

    // extra pay
    case 30:
      return "Reimbursement";
    case 31:
      return "Time and half";
    case 32:
      return "Double time";
    case 33:
      return "Bonus";
    case 34:
      return "Commission";
    case 35:
      return "Back pay";
    case 36:
      return "Retirement payment";
    case 37:
      return "Redundancy payment";
    case 38:
      return "Extra pay other";

    case 40:
      return "Leave available final pay";
    case 41:
      return "Alternative leave final pay";
    case 42:
      return "Leave accrual holiday pay";

    //extra deduction
    case 50:
      return "Work and income debt";
    case 51:
      return "Court fine";
    case 52:
      return "Child support";
    case 53:
      return "IRD arrears";
    case 54:
      return "SLCIR rate";
    case 55:
      return "SLBOR amount";
    case 56:
      return "Deduction other";

    //tax calculation

    case 71:
      return "PAYE deduction";
    case 72:
      return "ACC levy";
    case 73:
      return "Student loan";
    case 74:
      return "Employee kiwisaver";

    //Employer Kiwisaver,

    case 80:
      return "Employer kiwisaver contribution";
    case 81:
      return "Employer kiwisaver contribution tax";
    case 82:
      return "Employer kiwisaver contribution net";

    //netPay to employee
    case 90:
      return "Net pay to employee";

    default:
      return "";
  }
};

export const EmployeeLeaveTypeArray: { name: string; value: number }[] = [
  { name: "Public holiday", value: PayTypeEnum.PublicHoliday },
  { name: "Annual leave taken", value: PayTypeEnum.AnnualLeave },
  { name: "Sick leave taken", value: PayTypeEnum.Sickleave },
  {
    name: "Alternative leave - taken",
    value: PayTypeEnum.AlternativeLeaveTaken,
  },
  // { name: "Alternative leave - add (increase)", value: 4 },
  { name: "Family violence leave taken", value: PayTypeEnum.FamilyViolence },
  { name: "Parental leave taken", value: PayTypeEnum.ParentalLeaveTaken },
  { name: "Bereavemen leave taken", value: PayTypeEnum.BereavementLeave },
];

export const PayrunStatusEnum = {
  Draft: 0,
  Approved: 1,

  PaidAuto: 2,
  PaidManual: 3,

  OkToPayEmployee: 4,
  // OKToFileToIRD: 5,

  // OkToPayPAYEHolding: 6,
  // OkToPayServiceFee: 7,
  OkToPayIRDFromHolding: 8,

  AllPaymentCleared: 9,
};

export const PayrunStatusEnumList = [
  PayrunStatusEnum.Draft,
  PayrunStatusEnum.Approved,
  PayrunStatusEnum.PaidAuto,
  PayrunStatusEnum.PaidManual,
  PayrunStatusEnum.OkToPayEmployee,
  // PayrunStatusEnum.OKToFileToIRD,

  // PayrunStatusEnum.OkToPayPAYEHolding,
  // PayrunStatusEnum.OkToPayServiceFee,
  PayrunStatusEnum.OkToPayIRDFromHolding,

  PayrunStatusEnum.AllPaymentCleared,
];

export const PayrunPaidType = {
  Manual: 1,
  Auto: 2,
};

export const PayrunFilingStatusEnum = {
  NotFileYet: 0,
  Successful: 1,
  Failed: 2,
};

export const EmployeeToPay_PaidOutStatus = {
  NotPaidYet: 0,
  ProcessedOrPaid: 1,
  Reversed: 2,
  ReProcessedBankError: 3,
};

export const IRDFilingResultEnum = {
  UnknowError: -1,

  Success: 0,

  AuthenticationFail: 1,

  MissingAuthToken: 2,

  UnAuthorisedAccess: 3,

  UnAuthorisedDelegation: 4,

  UnAuthorisedVendor: 5,

  InvalidFilingPeriod: 104,

  DuplicateReturn: 107,

  EmployeeIrdNumberWrong: 134,

  AmendmentBlockedUntilInitialProcessed: 144,
};
