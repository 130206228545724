import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import shortid from "shortid";
import {
  GetPayTypeName,
  PayTypeCategoryNumber,
} from "../afterLogin/Employees/EmployeeRelatedEnum";
import { CheckEmployeePayTypeCategory } from "../afterLogin/Payroll/AddPayroll/PayrunCommonMethod";
import {
  IPayrunEmployeeToPayDetailDTO,
  IUpcomingReimbursementOrDeductionDTO,
} from "../interface/UserInterface";
import { store } from "./store";

export default class EmployeeReimburseDeductionStore {
  reimbursementDeductionFormValue: IUpcomingReimbursementOrDeductionDTO = {
    id: shortid(),
    irdUserEmployeeId: "",
    employeeName: "",
    payTypeId: -1,
    payTypeName: "",
    isAddAlternativeLeave: false,
    isExtraPay: false,
    isDeduction: false,
    description: "",
    note: "",
    isSubjectToTax: false,
    isRecurringItem: false,
  };

  drawerStatus: {
    whereIsFrom: number | undefined;
    isEditMode: boolean;
    isShow: boolean;
  } = {
    whereIsFrom: undefined,
    isEditMode: false,
    isShow: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  onClosehandler = async (isGetDataFromDB: boolean): Promise<void> => {
    this.setDrawerStatus(undefined, false, false, false, false);

    this.reSetReimbursementDeductionForm();

    if (isGetDataFromDB === true) {
      await store.irdUserStore.getCurrentIrdUserFromDB(
        "employeeReimburseDeductionStore"
      );
      await store.employeeStore.getCurrentEmployee(() => {});
    }
  };

  setDrawerStatus = (
    whereIsFrom: number | undefined,
    isEditMode: boolean,
    isShow: boolean,
    isExtraPay: boolean,
    isDeduction: boolean
  ) => {
    this.drawerStatus = {
      whereIsFrom: whereIsFrom,
      isEditMode: isEditMode,
      isShow: isShow,
      // isExtraPay: isExtraPay,
      // isDeduction: isDeduction
    };

    this.reimbursementDeductionFormValue.isExtraPay = isExtraPay;
    this.reimbursementDeductionFormValue.isDeduction = isDeduction;
  };

  reSetReimbursementDeductionForm = () => {
    this.reimbursementDeductionFormValue = {
      id: shortid(),
      irdUserEmployeeId: "",
      payTypeId: -1,
      payTypeName: "",
      isAddAlternativeLeave: false,
      employeeName: "",
      description: "",
      note: "",
      isExtraPay: false,
      isDeduction: false,
      isSubjectToTax: false,
      isRecurringItem: false,
    };
  };

  setReimbursementDeductionFormValueViaDuringPayrun = (
    requestItem: IPayrunEmployeeToPayDetailDTO
  ) => {
    let temp: IUpcomingReimbursementOrDeductionDTO = {
      id: requestItem.id,
      irdUserEmployeeId: requestItem.irdUserEmployeeId,
      employeeName: undefined,
      isExtraPay: CheckEmployeePayTypeCategory(
        requestItem.payTypeId,
        PayTypeCategoryNumber.ExtraPay
      ),
      isDeduction: CheckEmployeePayTypeCategory(
        requestItem.payTypeId,
        PayTypeCategoryNumber.Deduction
      ),
      payTypeId: requestItem.payTypeId,
      payTypeName: GetPayTypeName(requestItem.payTypeId),
      isAddAlternativeLeave: requestItem.isAddAlternativeLeave,
      howManyAlternativeLeaveToAdd: requestItem.howManyAlternativeLeaveToAdd,
      description: requestItem.description,
      amount: requestItem.amount,
      hoursToPay: requestItem.hoursToPay,
      note: requestItem.note,
      isSubjectToTax: requestItem.isSubjectToTax,

      isRecurringItem: requestItem.isRecurringItem ?? false,
      isDeleted: requestItem.isDeleted,
      destinationBankAccNumber: requestItem.destinationBankAccNumber,

      destinationBankAccName: requestItem.destinationBankAccName,
      receiverReference: requestItem.receiverReference,
      receiverCode: requestItem.receiverCode,
      receiverParticular: requestItem.receiverParticular,
      totalDeductionAmount: requestItem.totalDeductionAmount,
      slcirRate: requestItem.slcirRate,
    };

    this.setReimbursementDeductionFormValue(temp);
  };

  setReimbursementDeductionFormValue = (
    temp: IUpcomingReimbursementOrDeductionDTO
  ) => {
    if (temp.employeeName === undefined) {
      if (
        store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
          (x) => x.id === temp.irdUserEmployeeId
        ) !== undefined &&
        store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
          (x) => x.id === temp.irdUserEmployeeId
        ).length > 0
      ) {
        temp.employeeName =
          store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
            (x) => x.id === temp.irdUserEmployeeId
          )[0].firstName +
          " " +
          store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
            (x) => x.id === temp.irdUserEmployeeId
          )[0].lastName;
      }
    }
    if (temp.isExtraPay === true) {
      temp.payTypeName = GetPayTypeName(temp.payTypeId);
    }

    if (temp.isDeduction === true) {
      temp.payTypeName = GetPayTypeName(temp.payTypeId);
    }
    this.reimbursementDeductionFormValue = toJS(temp);
  };

  addHandler = async () => {
    store.loadingStore.setIsLoading(true);

    console.log(this.reimbursementDeductionFormValue);

    // this.reimbursementDeductionFormValue.isExtraPay = this.drawerStatus.isExtraPay;
    // this.reimbursementDeductionFormValue.isDeduction = this.drawerStatus.isDeduction;

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      UpcomingReimbursementDeduction: this.reimbursementDeductionFormValue,
    };

    let requestHeader = await store.authStore.getRequestHeader();

    axios
      .post(
        "/employee/AddUpcomingReimbursementDeduction",
        bodyParameters,
        requestHeader
      )
      .then(async (response) => {
        await this.onClosehandler(true);
        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          store.loadingStore.setIsLoading(false);

          console.log(error);
        }
      });
  };

  editHandler = async (isEdit: boolean, isDelete: boolean) => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      UpcomingReimbursementDeduction: this.reimbursementDeductionFormValue,
      IsDelete: isDelete,
      IsEdit: isEdit,
    };

    let requestHeader = await store.authStore.getRequestHeader();

    await axios
      .post(
        "/employee/EditUpcomingReimbursementDeduction",
        bodyParameters,
        requestHeader
      )
      .then(async (response) => {
        await this.onClosehandler(true);

        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          store.loadingStore.setIsLoading(false);

          console.log(error);
        }
      });
  };
}
