import { createContext, useContext } from "react";
import AdminMenuStore from "./AdminStore/adminMenuStore";
import AdminPayNowStore from "./AdminStore/adminPayNowStore";
import AuthStore from "./authStore";
import ClientMemuStore from "./clientMenuStore";
import DashboardStore from "./dashboardStore";
import EmployeeLeaveStore from "./employeeLeaveStore";
import EmployeeReimburseDeductionStore from "./employeeReimburseDeductionStore";
import EmployeeStore from "./employeeStore";
import IrdUserStore from "./irdUserStore";
import LandingPageStore from "./landingPageStore";
import LoadingStore from "./loadingStore";
import PayRunStore from "./payrunStore";
import AdminFileToIRDStore from "./AdminStore/adminFileToIRDStore";
import HowToStore from "./howToStore";
import CopyStore from "./copyStore";

interface Store {
  landingPageStore: LandingPageStore;

  authStore: AuthStore;
  irdUserStore: IrdUserStore;
  employeeStore: EmployeeStore;
  employeeLeaveStore: EmployeeLeaveStore;
  employeeReimburseDeductionStore: EmployeeReimburseDeductionStore;
  clientMemuStore: ClientMemuStore;
  dashboardStore: DashboardStore;
  payrunStore: PayRunStore;

  loadingStore: LoadingStore;

  adminMenuStore: AdminMenuStore;

  adminPayNowStore: AdminPayNowStore;

  adminFileToIRDStore: AdminFileToIRDStore;

  howToStore: HowToStore;

  copyStore: CopyStore;
}

export const store: Store = {
  landingPageStore: new LandingPageStore(),

  //client store
  authStore: new AuthStore(),
  irdUserStore: new IrdUserStore(),
  clientMemuStore: new ClientMemuStore(),
  employeeStore: new EmployeeStore(),
  employeeLeaveStore: new EmployeeLeaveStore(),
  employeeReimburseDeductionStore: new EmployeeReimburseDeductionStore(),
  dashboardStore: new DashboardStore(),
  payrunStore: new PayRunStore(),

  loadingStore: new LoadingStore(),

  //admin store
  adminMenuStore: new AdminMenuStore(),
  adminPayNowStore: new AdminPayNowStore(),
  adminFileToIRDStore: new AdminFileToIRDStore(),

  howToStore: new HowToStore(),

  copyStore: new CopyStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
