import React, { useEffect, useState } from "react";
import GetFont from "../../Media/fontFamily/GetFont";
import GoogleIcon from "../../Media/icon/GoogleIcon";
import EmailIcon from "../../Media/icon/MailIcon";
import axios from "axios";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Row,
  Button,
  Modal,
  Input,
  Statistic,
  message,
  Form,
  Drawer,
  Checkbox,
  Spin,
} from "antd";
import "./LoginComponent.css";
import { getEmailFromLocalStorage } from "../../utility/GetLocalStorageData";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../mobxStores/store";
import { observer } from "mobx-react-lite";
import { IAppUserDTO } from "../../interface/UserInterface";
import { LeftMenuBarEnum } from "../../afterLogin/LeftMenuBarEnum";
import { AdminLeftMenu } from "../../adminLogin/Setting/LeftNavBarAdmin";
import TermsCondition from "../../beforeLogin/TermsCondition";
import Privacy from "../../beforeLogin/Privacy";
import { IsCellPhone } from "../../utility/ResponsiveTool";

const { Countdown } = Statistic;

interface ISigninPropType {
  isShow: boolean;
  isLogin: boolean;
  isGetStarted: boolean;
}

var Recaptcha = require("react-recaptcha");

const LoginComponent: React.FC<ISigninPropType> = ({
  isShow,
  isLogin,
  isGetStarted,
}) => {
  const history = useNavigate();
  const { authStore, loadingStore, irdUserStore } = useStore();

  const loginViaGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => onSuccessViaGoogleLogin(tokenResponse),
  });

  const isCellphone = IsCellPhone();

  const [loginStepPosition, setLoginStepPosition] = useState<number>(1);

  const [termsConditionSetting, setTermsConditionSetting] = useState<{
    isAgreed: boolean;
    errorMessage: string;
  }>({ isAgreed: false, errorMessage: "" });

  const [EmailForm] = Form.useForm();
  const [FourDigitForm] = Form.useForm();

  const [hasCountFinished, setHasCountFinished] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [Email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const [verificationCode, setVerificationCode] = useState("");
  const [isShowTermsCondition, setIsShowTermsCondition] =
    useState<boolean>(false);

  const [isShowPrivacy, setIsShowPrivacy] = useState<boolean>(false);

  const afterLoginHandler = (temp: IAppUserDTO) => {
    console.log(temp);

    irdUserStore.setAppUser(temp);

    authStore.setJwtToken(temp.jwtToken);
    authStore.setEmailUsed(temp.email);
    authStore.setCurrentIrduserId(temp.currentSelectedIrdUser);

    setLoginStepPosition(5);

    setIsButtonLoading(false);

    loadingStore.setIsLoading(false);
  };

  const Check_IsEmailValid = async () => {
    let anyError: boolean = false;
    try {
      await EmailForm.validateFields();
    } catch (errorInfo) {
      anyError = true;
    }

    if (anyError === false) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const emailFormOnSubmit = async () => {
    let anyError: boolean = false;
    try {
      await EmailForm.validateFields();
    } catch (errorInfo) {
      anyError = true;
    }

    if (anyError === false) {
      setEmail(EmailForm.getFieldValue("Email"));

      setLoginStepPosition(loginStepPosition + 1);
    }
  };

  const [FourDigitError, setFourDigitError] = useState<string>("");

  const FourDigitCodeOnSubmit = async (verificationCode: string) => {
    let anyError: boolean = false;
    console.log("code confirm");
    try {
      const values = await FourDigitForm.validateFields();
      console.log(values);
    } catch (errorInfo) {
      anyError = true;
    }

    console.log(anyError);
    console.log(verificationCode);

    if (verificationCode !== "" && anyError === false) {
      setIsButtonLoading(true);
      const bodyParameters = {
        ActivationCode: verificationCode,
        Email: Email,
      };

      console.log(bodyParameters);

      axios
        .post("/beforelogin/LoginCodeConfirm", bodyParameters)
        .then((response) => {
          console.log("confirm success");
          let result: IAppUserDTO = response.data;
          afterLoginHandler(result);
        })
        .catch((error) => {
          // console.log(error.response.data.errors);
          // eslint-disable-next-line no-lone-blocks
          {
            console.log(error);
            console.log("confirm fail");
            // console.log(err);
            // message.error(err.toString());
            setFourDigitError("Wrong code");
            setIsButtonLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (isFourDigitEntered() === true) {
      FourDigitCodeOnSubmit(verificationCode);
    }
  }, [verificationCode]);

  useEffect(() => {
    // console.log(FourDigitError);
    if (FourDigitError !== "") {
      FourDigitForm.validateFields();
      console.log("210");
    }
  }, [FourDigitError]);

  const LoginCodeRequest = async (isReSendCode: boolean) => {
    const bodyParameters = {
      ClientEmail: Email,
    };
    let requestHeader = await authStore.getRequestHeader();

    axios
      .post("/beforelogin/LoginCodeRequest", bodyParameters, requestHeader)
      .then((response) => {
        // console.log(response.data.token);
        if (isReSendCode === false) {
          setLoginStepPosition(loginStepPosition + 1);
        }
      })
      .catch((error) => {
        console.log(error);

        // eslint-disable-next-line no-lone-blocks
        {
          Object.values(error.response.data.errors)
            .flat()
            .map((err, i) => message.error("Login error"));
        }
      });
  };

  const onSuccessViaGoogleLogin = (response: any) => {
    console.log(response);
    let accessToken = response.access_token;
    if (accessToken) {
      loadingStore.setIsLoading(true);
      axios
        .post("/beforelogin/GoogleLogin", {
          AccessToken: accessToken,
        })
        .then((response) => {
          let temp: IAppUserDTO = response.data;
          afterLoginHandler(temp);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            loadingStore.setIsLoading(false);
            Object.values(error.response.data.errors)
              .flat()
              .map((err, i) => message.error("Login Fail"));
          }
        });
    } else {
      message.error("google auth failed");
    }
  };

  const resendAgainHandler = () => {
    if (hasCountFinished) {
      setHasCountFinished(false);
      LoginCodeRequest(true);
    } else {
      console.log("Please wait for 60 seconds.");
    }
  };

  var reCAPTCHAKey = "";
  if (process.env.REACT_APP_BASE_RECAPTCHAKEY !== undefined) {
    reCAPTCHAKey = process.env.REACT_APP_BASE_RECAPTCHAKEY;
  }

  const ModalCancelHandler = () => {
    setLoginStepPosition(1);
    authStore.closeLogin();
    EmailForm.resetFields();
    FourDigitForm.resetFields();
  };

  const [isWaitingOnGoogleAPI, setIsWaitingOnGoogleAPI] =
    useState<boolean>(true);

  useEffect(() => {
    setIsWaitingOnGoogleAPI(false);
  }, []);

  const isFourDigitEntered = (): boolean => {
    if (verificationCode.length === 4) {
      return true;
    }
    return false;
  };

  const LoginJsx = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: isCellphone ? undefined : "center",
          fontFamily: GetFont("description"),
          height: "100%",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 16 }}
        >
          {loginStepPosition !== 1 && (
            <ArrowLeftOutlined
              style={{
                position: isCellphone ? undefined : "absolute",
                top: isCellphone ? undefined : 32,
                left: isCellphone ? undefined : 32,
                fontSize: 28,
                color: "#8C8C8C",
              }}
              onClick={() => {
                setLoginStepPosition(loginStepPosition - 1);
              }}
            />
          )}
        </div>
        <Spin spinning={loadingStore.isLoading}>
          {loginStepPosition === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: isCellphone ? "left" : "center",
                  // marginTop: isCellphone ? undefined : 72,
                  marginBottom: 48,
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    letterSpacing: "-0.02em",
                    fontSize: 56,
                    lineHeight: "150%",
                    color: "#262626",
                    fontFamily: GetFont("title"),
                  }}
                >
                  {isLogin === true && "Welcome back"}
                  {isGetStarted === true && "Get started"}
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 24,
                    lineHeight: "120%",
                    color: "#595959",
                    letterSpacing: "-0.05em",
                  }}
                >
                  {isLogin === true && getEmailFromLocalStorage() !== null && (
                    <>
                      <div>Last time login:</div>
                      <div>{getEmailFromLocalStorage()}</div>
                    </>
                  )}
                  {isGetStarted === true && "Creating your account in seconds"}
                </div>
              </div>

              <Button
                onClick={() => {
                  if (termsConditionSetting.isAgreed === true) {
                    loginViaGoogle();
                  } else {
                    setTermsConditionSetting({
                      ...termsConditionSetting,
                      errorMessage:
                        "Click the check box to agree the terms and conditions",
                    });
                  }
                }}
                style={{
                  background: "#F5F5F5",
                  borderRadius: 28,
                  width: 327,
                  height: 56,
                  border: 0,
                  marginBottom: 16,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoogleIcon />
                <span
                  style={{
                    marginLeft: 8,
                    fontSize: 18,
                    lineHeight: "130%",
                    fontWeight: 500,
                    color: "#262626",
                  }}
                >
                  {isLogin === true && "Sign in with Google"}
                  {isGetStarted === true && "Sign up with Google"}
                </span>
              </Button>

              <Button
                style={{
                  background: "#F5F5F5",
                  borderRadius: 28,
                  width: 327,
                  height: 56,
                  border: 0,
                  marginBottom: 32,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (termsConditionSetting.isAgreed === true) {
                    setLoginStepPosition(loginStepPosition + 1);
                    setEmail("");
                  } else {
                    setTermsConditionSetting({
                      ...termsConditionSetting,
                      errorMessage:
                        "Click the check box to agree the terms and conditions",
                    });
                  }
                }}
              >
                <EmailIcon />
                <span
                  style={{
                    marginLeft: 8,
                    fontSize: 18,
                    lineHeight: "130%",
                    fontWeight: 500,
                    color: "#262626",
                  }}
                >
                  {isLogin === true && "Sign in with Email"}
                  {isGetStarted === true && "Sign up with Email"}
                </span>
              </Button>

              {termsConditionSetting.isAgreed === false &&
                termsConditionSetting.errorMessage !== "" && (
                  <div
                    style={{
                      color: "red",
                      marginBottom: 12,
                    }}
                  >
                    {termsConditionSetting.errorMessage}
                  </div>
                )}

              <div
                style={{
                  fontSize: 12,
                  lineHeight: "130%",
                  color: "#8C8C8C",
                  textAlign: "center",
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setTermsConditionSetting({
                        isAgreed: true,
                        errorMessage: "",
                      });
                    } else {
                      setTermsConditionSetting({
                        isAgreed: false,
                        errorMessage:
                          "Click the check box to agree the terms and conditions",
                      });
                    }
                  }}
                  checked={termsConditionSetting.isAgreed}
                ></Checkbox>{" "}
                <span>
                  By ticking the check box, you are indicating that you have
                  read and agree to the{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: 6,
                      color:
                        termsConditionSetting.isAgreed === false &&
                        termsConditionSetting.errorMessage !== ""
                          ? "red"
                          : undefined,
                      fontWeight:
                        termsConditionSetting.isAgreed === false &&
                        termsConditionSetting.errorMessage !== ""
                          ? 600
                          : undefined,
                    }}
                    onClick={() => {
                      setIsShowTermsCondition(true);
                    }}
                  >
                    Terms and Service
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: 6,
                      color:
                        termsConditionSetting.isAgreed === false &&
                        termsConditionSetting.errorMessage !== ""
                          ? "red"
                          : undefined,
                      fontWeight:
                        termsConditionSetting.isAgreed === false &&
                        termsConditionSetting.errorMessage !== ""
                          ? 600
                          : undefined,
                    }}
                    onClick={() => setIsShowPrivacy(true)}
                  >
                    Privacy Policy
                  </span>
                  .
                </span>
              </div>

              {isShowTermsCondition && (
                <TermsCondition
                  isShow={isShowTermsCondition}
                  closeHandler={() => {
                    setIsShowTermsCondition(false);
                  }}
                  isModal={true}
                />
              )}

              {isShowPrivacy && (
                <Privacy
                  isShow={isShowPrivacy}
                  closeHandler={() => {
                    setIsShowPrivacy(false);
                  }}
                  isModal={true}
                />
              )}
            </div>
          )}
          {loginStepPosition === 2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Form
                layout="vertical"
                requiredMark={false}
                form={EmailForm}
                name="EmailForm"
                onFinish={emailFormOnSubmit}
                validateMessages={{
                  required: "${label} is required",
                  types: {
                    email: "Not a valid email",
                  },
                }}
                autoComplete={"off"}
              >
                <Form.Item
                  label={
                    <div
                      style={{
                        marginBottom: 24,
                        fontSize: 48,
                        fontFamily: GetFont("title"),
                      }}
                    >
                      {isLogin === true && "Sign in with email"}
                      {isGetStarted === true && "Sign up with email"}
                    </div>
                  }
                  name={"Email"}
                  rules={[{ type: "email", required: true }]}
                >
                  <Input
                    bordered={false}
                    onPressEnter={() => emailFormOnSubmit()}
                    placeholder="Enter email address"
                    style={{
                      border: 0,
                      borderBottom: "1px solid #595959",
                      boxSizing: "border-box",
                      width: isCellphone ? "100%" : 440,
                      height: 64,
                      fontSize: 32,
                      fontFamily: GetFont("description"),
                      textAlign: "center",
                    }}
                    onChange={() => {
                      let temp = EmailForm.getFieldValue("Email").trim();
                      EmailForm.setFieldValue("Email", temp);
                      Check_IsEmailValid();
                    }}
                  />
                </Form.Item>
              </Form>

              <Button
                ghost
                // disabled={useEmail && !EmailValidator.validate(Email)}
                style={{
                  border: 0,
                  borderRadius: 28,
                  color: "#FFFFFF",
                  padding: "16px 32px",
                  fontSize: 18,
                  lineHeight: "130%",
                  fontWeight: 700,
                  marginTop: 32,
                  height: "fit-content",
                  width: "fit-content",
                  backgroundColor:
                    isEmailValid === true ? "#0039FF" : "#E8E8E8",
                }}
                onClick={() => emailFormOnSubmit()}
                disabled={isEmailValid === false}
              >
                Get login code
              </Button>
            </div>
          )}
          {loginStepPosition === 3 && (
            <div
              style={{
                margin: "80px 0px 80px 0px ",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: 32,
                  lineHeight: "100%",
                  color: "#000000",
                  marginBottom: 24,
                  alignSelf: "center",
                }}
              >
                I'm not a robot
              </div>
              <Recaptcha
                sitekey={reCAPTCHAKey}
                render="explicit"
                verifyCallback={() => {
                  LoginCodeRequest(false);
                }}
                onloadCallback={() => {
                  console.log("google loaded");
                }}
              />
            </div>
          )}
          {loginStepPosition === 4 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Form
                layout="vertical"
                requiredMark={false}
                form={FourDigitForm}
                name="FourDigitForm"
                onFinish={emailFormOnSubmit}
                validateMessages={{
                  required: "Please input 4 digit code",
                }}
                autoComplete={"off"}
              >
                <Form.Item
                  label={
                    <div
                      style={{
                        marginBottom: 24,
                        fontSize: 48,
                        fontFamily: GetFont("title"),
                        flexWrap: "nowrap",
                        overflowWrap: "anywhere",
                      }}
                    >
                      {Email}
                    </div>
                  }
                  name={"Code"}
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (FourDigitError === "") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            <div style={{ marginTop: 12 }}>Invalid Code</div>
                          );
                        }
                      },
                    }),
                  ]}
                  style={{ textAlign: "center" }}
                >
                  <Input
                    maxLength={4}
                    bordered={false}
                    onPressEnter={() => FourDigitCodeOnSubmit(verificationCode)}
                    placeholder="Enter 4-digit code"
                    style={{
                      border: 0,
                      borderBottom: "1px solid #595959",
                      boxSizing: "border-box",
                      width: isCellphone ? "100%" : 440,
                      height: 64,
                      fontSize: 32,
                      lineHeight: "200%",
                      fontFamily: GetFont("description"),
                      textAlign: "center",
                      letterSpacing:
                        verificationCode.length > 0 ? "1em" : undefined,
                    }}
                    onChange={(value) => {
                      if (FourDigitError !== "") {
                        setFourDigitError("");
                      }
                      setVerificationCode(value.target.value);
                    }}
                  />
                </Form.Item>
              </Form>

              <div
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#8c8c8c",
                  marginBottom: 64,
                  width: isCellphone ? "100%" : 440,
                  textAlign: "center",
                }}
              >
                Didn't get the email? Check email spam, junk, or promotion
                folder.
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {hasCountFinished ? null : (
                  <div
                    style={{
                      marginRight: 16,
                      fontWeight: 700,
                      fontSize: 18,
                      lineHeight: "130%",
                      color: "#3B873E",
                    }}
                  >
                    <CheckCircleFilled
                      style={{ color: "3B873E", marginRight: 12 }}
                    />
                    Code sent
                  </div>
                )}

                <Countdown
                  valueStyle={{
                    fontSize: 18,
                    lineHeight: "130%",
                    color: "#595959",
                  }}
                  prefix="Resent "
                  suffix="s"
                  format={"s"}
                  value={hasCountFinished ? Date.now() : Date.now() + 10 * 1000}
                  onFinish={() => {
                    setHasCountFinished(true);
                  }}
                />
                {hasCountFinished ? (
                  <Button
                    type="link"
                    style={{
                      marginLeft: 16,
                      border: 0,
                      padding: 0,
                      fontWeight: 700,
                      fontSize: 18,
                      lineHeight: "130%",
                      color: "#0063FF",
                    }}
                    onClick={() => {
                      resendAgainHandler();
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: 6,
                      }}
                    >
                      Resend code
                    </span>
                  </Button>
                ) : null}
              </div>

              <Button
                ghost
                // disabled={useEmail && !EmailValidator.validate(Email)}
                style={{
                  border: 0,
                  borderRadius: 28,
                  color: "#FFFFFF",
                  padding: "16px 32px",
                  fontSize: 18,
                  lineHeight: "130%",
                  fontWeight: 700,
                  marginTop: 32,
                  height: "fit-content",
                  width: "fit-content",
                  backgroundColor: "#0039FF",
                }}
                onClick={() => {
                  FourDigitCodeOnSubmit(verificationCode);
                }}
                disabled={isFourDigitEntered() === false}
                loading={isButtonLoading}
              >
                Continue with login code
              </Button>
            </div>
          )}
          {loginStepPosition === 5 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
              }}
            >
              <Row
                justify="center"
                style={{
                  marginBottom: 32,
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 32,
                  lineHeight: "100%",
                  color: "#29CCB6",
                }}
              >
                Log in successfully
              </Row>

              <Row justify="center">
                <Countdown
                  valueStyle={{
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "130%",
                    color: "#595959",
                  }}
                  prefix="Auto close in "
                  suffix="s"
                  format={"ss"}
                  value={Date.now() + 1800}
                  onFinish={() => {
                    ModalCancelHandler();

                    if (irdUserStore.appUser?.isAdmin === true) {
                      // push to admin
                      history("/admin/" + AdminLeftMenu.Dashboard_B);
                    } else {
                      // push to client
                      history(
                        `/Dashboard/${LeftMenuBarEnum.Dashboard}/${irdUserStore.appUser?.currentSelectedIrdUser}`
                      );
                    }
                  }}
                />
              </Row>
            </div>
          ) : null}{" "}
        </Spin>
      </div>
    );
  };

  return isCellphone ? (
    <Drawer
      open={isShow}
      placement={"bottom"}
      height={"auto"}
      closable={true}
      onClose={() => {
        ModalCancelHandler();
      }}
      closeIcon={
        <CloseOutlined
          style={{
            position: "absolute",
            top: 32,
            right: 32,
            fontSize: 28,
            color: "#8C8C8C",
          }}
        />
      }
      headerStyle={{ borderBottom: 0 }}
      bodyStyle={{ paddingTop: 0 }}
    >
      <div style={{ paddingBottom: 32 }}>{LoginJsx()}</div>
    </Drawer>
  ) : (
    <Modal
      centered={true}
      open={isShow}
      footer={null}
      onCancel={() => {
        ModalCancelHandler();
      }}
      width={600}
      style={{}}
      bodyStyle={{
        borderRadius: 12,
        backgroundColor: "#FFFFFF",
        padding: 48,
        // height: 560,
        // width: 560,
      }}
      closeIcon={
        <CloseOutlined
          style={{
            position: "absolute",
            top: 32,
            right: 32,
            fontSize: 28,
            color: "#8C8C8C",
          }}
        />
      }
      closable={authStore.isAllowLoginClose}
      maskClosable={false}
    >
      {LoginJsx()}
    </Modal>
  );
};

export default observer(LoginComponent);
