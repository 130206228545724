import axios from "axios";
import { action, makeAutoObservable, observable, toJS } from "mobx";
import moment from "moment";
import shortid from "shortid";
import { GetPayTypeName } from "../afterLogin/Employees/EmployeeRelatedEnum";
import { IIRDUserEmployeeUpcomingLeaveDTO } from "../interface/UserInterface";
import { store } from "./store";

export const leaveDrawerPositionEnum = {
  MainDashboard: 1,
  EmployeeViewPage: 2,
  DuringPayrun: 3,
};

export default class EmployeeLeaveStore {
  leaveFormValue: IIRDUserEmployeeUpcomingLeaveDTO = {
    id: shortid(),
    irdUserEmployeeId: "",
    employeeName: "",
    recordedDateTime: null,
    leaveTypeId: -1,
    leaveType: "",
    manyIRDUserEmployeeUpcomingLeaveDetailDTO: [],
    isAllUpcomingLeaveProcessed: false,
    isDeleted: false,
  };

  leaveDrawerStatus: {
    leaveDrawerPosition: number;
    isEditMode: boolean;
    isShow: boolean;
  } = {
    leaveDrawerPosition: 0,
    isEditMode: false,
    isShow: false,
  };

  constructor() {
    makeAutoObservable(this, {
      leaveDrawerStatus: observable,
      leaveFormValue: observable,
      onClosehandler: action,
      SetLeaveDrawerStatus: action,
      reSetLeaveForm: action,
      removeLeaveDetail: action,
      setLeaveFormValue: action,
      LeaveFormAddHandler: action,
      LeaveFormEditHandler: action,
    });
  }

  onClosehandler = async (isGetDataFromDB: boolean): Promise<void> => {
    this.SetLeaveDrawerStatus(0, false, false);

    this.reSetLeaveForm();

    if (isGetDataFromDB === true) {
      await store.irdUserStore.getCurrentIrdUserFromDB("employeeLeaveStore");
    }
  };

  SetLeaveDrawerStatus = (
    leaveDrawerPosition: number,
    isEditMode: boolean,
    isShow: boolean
  ) => {
    this.leaveDrawerStatus = {
      leaveDrawerPosition: leaveDrawerPosition,
      isEditMode: isEditMode,
      isShow: isShow,
    };
  };

  reSetLeaveForm = () => {
    this.leaveFormValue = {
      id: shortid(),
      irdUserEmployeeId: "",
      employeeName: "",
      recordedDateTime: null,
      leaveTypeId: 0,
      leaveType: "",
      manyIRDUserEmployeeUpcomingLeaveDetailDTO: [],
      isAllUpcomingLeaveProcessed: false,
      isDeleted: false,
    };
  };

  removeLeaveDetail = (removeDate: string) => {
    let temp = toJS(
      this.leaveFormValue.manyIRDUserEmployeeUpcomingLeaveDetailDTO
    );

    console.log(temp);

    temp.forEach((item) => {
      if (
        moment(item.leaveDateTime, "YYYY-MM-DD").isSame(
          moment(removeDate, "YYYY-MM-DD")
        )
      ) {
        item.isDeleted = true;
      }
    });

    console.log(temp);

    this.leaveFormValue.manyIRDUserEmployeeUpcomingLeaveDetailDTO = temp;

    console.log(this.leaveFormValue.manyIRDUserEmployeeUpcomingLeaveDetailDTO);
  };

  setLeaveFormValue = (temp: IIRDUserEmployeeUpcomingLeaveDTO) => {
    this.leaveFormValue = toJS(temp);

    console.log(toJS(temp));

    console.log(toJS(this.leaveFormValue.employeeName));

    console.log(
      toJS(store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO)
    );

    if (
      this.leaveFormValue.employeeName === undefined ||
      this.leaveFormValue.employeeName === ""
    ) {
      if (
        store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
          (x) => x.id === temp.irdUserEmployeeId
        ) !== undefined &&
        store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
          (x) => x.id === temp.irdUserEmployeeId
        ).length > 0
      ) {
        this.leaveFormValue.employeeName =
          store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
            (x) => x.id === temp.irdUserEmployeeId
          )[0].firstName +
          " " +
          store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
            (x) => x.id === temp.irdUserEmployeeId
          )[0].lastName;
      }
    }

    if (this.leaveFormValue.leaveType === undefined) {
      this.leaveFormValue.leaveType = GetPayTypeName(temp.leaveTypeId);
    }
  };

  LeaveFormAddHandler = async () => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      EmployeeLeaveData: this.leaveFormValue,
    };

    let requestHeader = await store.authStore.getRequestHeader();

    axios
      .post("/employee/AddUpcomingLeave", bodyParameters, requestHeader)
      .then(async (response) => {
        store.loadingStore.setIsLoading(false);

        await this.onClosehandler(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          store.loadingStore.setIsLoading(false);

          console.log(error);
        }
      });
  };

  LeaveFormEditHandler = async (isEdit: boolean, isDelete: boolean) => {
    store.loadingStore.setIsLoading(true);

    console.log(toJS(this.leaveFormValue));

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      EmployeeLeaveData: this.leaveFormValue,
      IsDelete: isDelete,
      IsEdit: isEdit,
    };

    let requestHeader = await store.authStore.getRequestHeader();

    axios
      .post("/employee/EditUpcomingLeave", bodyParameters, requestHeader)
      .then((response) => {
        store.loadingStore.setIsLoading(false);

        this.onClosehandler(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          store.loadingStore.setIsLoading(false);

          console.log(error);
        }
      });
  };
}
