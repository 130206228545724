import { makeAutoObservable } from "mobx";
import { AdminLeftMenu } from "../../adminLogin/Setting/LeftNavBarAdmin";

export default class AdminMenuStore {
  currentMemu: string = AdminLeftMenu.Dashboard_B;

  menuCollapsed: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentMenu = (temp: string | undefined) => {
    if (temp !== undefined) {
      this.currentMemu = temp;
    }
  };

  setCollapsed = () => {
    this.menuCollapsed = !this.menuCollapsed;
  };
}
