import { Menu } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import GetLogo from "../../Media/logo/GetLogo";
import { useStore } from "../../mobxStores/store";
import { Dispatch, SetStateAction } from "react";

export const AdminLeftMenu = {
  Dashboard: "Dashboard",
  Dashboard_B: "Dashboard_B",
  AllClient: "All_Clients",
  Payrun_History: "Payrun_History",
  EmployeeList: "Employee_List",
  Setting: "Setting",
};

const LeftNavBarAdmin = () => {
  const { adminMenuStore } = useStore();
  const history = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <div
        style={{
          // width: 144,
          // height: 132,
          display: "flex",
          alignItems: "center",
          padding: 24,
        }}
      ></div> */}
      <div
        style={{
          display: "flex",
          padding: adminMenuStore.menuCollapsed ? 12 : 24,
          // marginLeft: "auto",
          // marginRight: "auto",
          // width: "50%",
          // height: "-webkit-fill-available",
          alignSelf: "center",
        }}
        onClick={() => {
          adminMenuStore.setCollapsed();
        }}
      >
        {GetLogo("53", "66", true, undefined)}
      </div>

      <Menu
        defaultSelectedKeys={[adminMenuStore.currentMemu]}
        mode="inline"
        items={[
          // {
          //   label: AdminLeftMenu.Dashboard,
          //   key: AdminLeftMenu.Dashboard,
          // },
          {
            label: AdminLeftMenu.Dashboard_B,
            key: AdminLeftMenu.Dashboard_B,
          },
          {
            label: AdminLeftMenu.AllClient,
            key: AdminLeftMenu.AllClient,
          },
          {
            label: AdminLeftMenu.Payrun_History,
            key: AdminLeftMenu.Payrun_History,
          },
          {
            label: AdminLeftMenu.EmployeeList,
            key: AdminLeftMenu.EmployeeList,
          },
          // {
          //   label: AdminLeftMenu.AdminList,
          //   key: AdminLeftMenu.AdminList,
          // },
          {
            label: AdminLeftMenu.Setting,
            key: AdminLeftMenu.Setting,
          },
          // {
          //   label: AdminLeftMenu.Cronjob,
          //   key: AdminLeftMenu.Cronjob,
          // },
          // {
          //   label: AdminLeftMenu.Coupon,
          //   key: AdminLeftMenu.Coupon,
          // },
        ]}
        onClick={(e) => {
          adminMenuStore.setCurrentMenu(e.key);
          history(`/admin/${e.key}`);
        }}
        inlineCollapsed={adminMenuStore.menuCollapsed}
      />
    </div>
  );
};

export default observer(LeftNavBarAdmin);
