import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../mobxStores/store";
import { OvalActionButton } from "../commonElement/commonElement";

const AuthLockOut = () => {
  const { authStore } = useStore();
  const history = useNavigate();

  return (
    <Modal
      open={authStore.isShowLogOutScreen}
      footer={null}
      closeIcon={<div></div>}
      bodyStyle={{
        padding: "90px 24px 60px 24px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        height: "fit-content",
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: 32,
          color: "#000000",
          marginBottom: 16,
          lineHeight: "120%",
        }}
      >
        Your account has been logged out for safety reasons.
      </div>

      <div
        style={{
          fontWeight: 700,
          fontSize: 18,
          color: "#909090",
          marginBottom: 32,
        }}
      >
        Please login again.
      </div>

      <OvalActionButton
        text="OK"
        onClickHandler={() => {
          authStore.closeAuthLogout();
          authStore.clearJwtToken();
          history("/");
        }}
        style={{ width: 327, height: 56 }}
      />
    </Modal>
  );
};

export default observer(AuthLockOut);
