const GoogleIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.15407 13.6904L5.42309 16.4192L2.75135 16.4757C1.9529 14.9948 1.5 13.3004 1.5 11.4998C1.5 9.75861 1.92345 8.11667 2.67404 6.6709H2.67461L5.05321 7.10698L6.09517 9.4713C5.87709 10.1071 5.75823 10.7896 5.75823 11.4998C5.75831 12.2705 5.89793 13.009 6.15407 13.6904Z"
        fill="#FBBB00"
      />
      <path
        d="M22.3172 9.53857C22.4378 10.1737 22.5007 10.8297 22.5007 11.5001C22.5007 12.2519 22.4216 12.9851 22.2711 13.6925C21.7599 16.0994 20.4243 18.2011 18.5741 19.6884L18.5736 19.6879L15.5776 19.535L15.1536 16.888C16.3813 16.1681 17.3407 15.0413 17.8461 13.6925H12.2314V9.53857H17.928H22.3172Z"
        fill="#518EF8"
      />
      <path
        d="M18.5736 19.6881L18.5742 19.6886C16.7748 21.135 14.4889 22.0004 12.0007 22.0004C8.00199 22.0004 4.52544 19.7654 2.75195 16.4763L6.15467 13.6909C7.0414 16.0575 9.3243 17.7421 12.0007 17.7421C13.151 17.7421 14.2288 17.4311 15.1536 16.8882L18.5736 19.6881Z"
        fill="#28B446"
      />
      <path
        d="M18.703 3.41731L15.3014 6.20213C14.3443 5.60387 13.2129 5.25827 12.0008 5.25827C9.26388 5.25827 6.93828 7.02019 6.09598 9.47159L2.67538 6.67118H2.6748C4.42233 3.30193 7.94272 1 12.0008 1C14.5485 1 16.8845 1.90752 18.703 3.41731Z"
        fill="#F14336"
      />
    </svg>
  );
};

export default GoogleIcon;
