const GetFont = (level: string): string | undefined => {
  let whichLogo: string = "";

  if (process.env.REACT_APP_BASE_Logo !== undefined) {
    whichLogo = process.env.REACT_APP_BASE_Logo;
  }

  if (level.toLowerCase() === "title") {
    if (whichLogo.toLowerCase() === "flash") {
      return "Exo";
    } else {
      return "Serif";
    }
  }

  if (level.toLowerCase() === "description") {
    if (whichLogo.toLowerCase() === "flash") {
      return "Inter";
    } else {
      return "Serif";
    }
  }

  return undefined;
};

export default GetFont;
