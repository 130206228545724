import { FormInstance } from "antd";
import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import moment from "moment";
import shortid from "shortid";
import {
  PayFrequencyEnum,
  PayTypeEnum,
  PayrunStatusEnum,
} from "../afterLogin/Employees/EmployeeRelatedEnum";
import { LeftMenuBarEnum } from "../afterLogin/LeftMenuBarEnum";
import {
  IPayrunDTO,
  IPayrunEmployeeToPayDTO,
  IPayrunEmployeeToPayDetailDTO,
  IUpcomingReimbursementOrDeductionDTO,
} from "../interface/UserInterface";
import { store } from "./store";

export default class PayRunStore {
  currentPayrun: IPayrunDTO = {
    id: shortid(),
    irdUserId: "",
    isDeleted: false,
    payFrequency: 0,
    payPeriodStartDate: "",
    payPeriodEndDate: "",
    payOnDate: "",
    manyPayrunEmployeeToPayDTO: [],

    businessNameSnapshot: "",
    irdNumberSnapshot: "",
    paidType: -1,
    hasUpcomingExtraPayDeductionDoneAndNoNeedAgain: false,
    hasUpcomingLeaveDoneAndNoNeedAgain: false,
  };

  currentStep: number = 0;

  isShow_Txt_Upload_Message: boolean = false;

  isFromReview: boolean = false;

  isDisableEdit: boolean = true;

  isShowAlreadyPaidWarning: {
    isShow: boolean;
    isDashboard: boolean;
    isDuringPayrun: boolean;
  } = {
    isShow: false,
    isDashboard: false,
    isDuringPayrun: false,
  };

  isShowPayrunChangedWarning: {
    isShow: boolean;
    requestAmount: number;
    dbAmount: number;
  } = { isShow: false, requestAmount: 0, dbAmount: 0 };

  constructor() {
    makeAutoObservable(this);
  }

  setIsShow_Txt_Upload_Message = (temp: boolean) => {
    this.isShow_Txt_Upload_Message = temp;
  };

  SetIsShowPayrunChangedWarning = (
    isShow: boolean,
    requestAmount: number,
    dbAmount: number
  ) => {
    this.isShowPayrunChangedWarning = {
      isShow: isShow,
      requestAmount: requestAmount,
      dbAmount: dbAmount,
    };
  };

  SetIsShowAlreadyPaidWarning = (
    isShow: boolean,
    isDashboard: boolean,
    isDuringPayrun: boolean
  ) => {
    this.isShowAlreadyPaidWarning = {
      isShow: isShow,
      isDashboard: isDashboard,
      isDuringPayrun: isDuringPayrun,
    };
  };

  SavePayrun = async (
    isApprove: boolean,
    payrunResult: IPayrunDTO
  ): Promise<boolean> => {
    let isPayrunPaid = await this.isPayrunPaid();

    if (isPayrunPaid === true) {
      this.SetIsShowAlreadyPaidWarning(true, false, true);

      return false;
    } else {
      this.setIrdUserForCurrentPayrun();

      store.loadingStore.setIsLoading(true);

      // remember, this payrunResult is not quite the currentPayrun, this is the calculated payrun
      payrunResult.irdUserId = this.currentPayrun.irdUserId;

      const bodyParameters = {
        AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
        PayrunDetail: payrunResult,
        IsApproved: isApprove,
        SavedFromWhichStep: this.currentStep.toString(),
      };
      console.log("Saving payroll");
      console.log(bodyParameters);
      console.log(toJS(payrunResult));

      let result: boolean = false;

      let requestHeader = await store.authStore.getRequestHeader();

      await axios
        .post("/Payrun/SaveCurrentPayrun", bodyParameters, requestHeader)
        .then((response) => {
          store.loadingStore.setIsLoading(false);

          store.clientMemuStore.setCurrentMenu(LeftMenuBarEnum.Dashboard);

          result = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            store.loadingStore.setIsLoading(false);

            result = false;
          }
        });

      return result;
    }
  };

  setDisableEdit = (isDisable: boolean) => {
    this.isDisableEdit = isDisable;
  };

  setCurrentStep = (step: number) => {
    this.currentStep = step;
  };

  setHasExtraPayDeduction = (request: boolean) => {
    this.currentPayrun.hasAdditionalExtraPayDeduction = request;
  };

  setIrdUserForCurrentPayrun = () => {
    this.currentPayrun.irdUserId = store.irdUserStore.currentIrdUser?.id ?? "";
  };

  removeEmployeeToPay = (tempEmployeeId: string) => {
    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach((item) => {
      if (item.employeeId === tempEmployeeId) {
        item.isRemovedFromPayrun = true;
      }
    });
  };

  isPayrunChanged = async (
    payrunAmount: number,
    payrunId: string
  ): Promise<boolean | undefined> => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      CurrentPayrunId: payrunId,
      PayrunAmount: payrunAmount,
    };

    let requestHeader = await store.authStore.getRequestHeader();

    let isPayrunChanged: boolean | undefined = undefined;

    await axios
      .post("/Payrun/IsPayrunChanged", bodyParameters, requestHeader)
      .then((response) => {
        store.loadingStore.setIsLoading(false);

        console.log(response.data);

        isPayrunChanged = response.data.item2;

        let requesstAmount: number = response.data.item3;
        let dbAmount: number = response.data.item4;

        if (isPayrunChanged === true) {
          this.SetIsShowPayrunChangedWarning(
            isPayrunChanged,
            requesstAmount,
            dbAmount
          );
        }
        // setReRender(shortid);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
          store.loadingStore.setIsLoading(false);
        }
      });

    return isPayrunChanged;
  };

  isBefore3PM = async (): Promise<boolean | undefined> => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {};
    let requestHeader = await store.authStore.getRequestHeader();

    let result: boolean | undefined = undefined;
    await axios
      .post("/Payrun/IsBefore3PM", bodyParameters, requestHeader)
      .then((response) => {
        store.loadingStore.setIsLoading(false);

        result = response.data;
        // setReRender(shortid);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
          store.loadingStore.setIsLoading(false);
        }
      });

    return result;
  };

  isPayrunPaid = async (payrunId?: string): Promise<boolean | undefined> => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      CurrentPayrunId:
        payrunId !== undefined ? payrunId : this.currentPayrun.id,
    };
    let requestHeader = await store.authStore.getRequestHeader();

    let result: boolean | undefined = undefined;
    await axios
      .post("/Payrun/IsPayrunPaid", bodyParameters, requestHeader)
      .then((response) => {
        store.loadingStore.setIsLoading(false);

        result = response.data;
        // setReRender(shortid);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
          store.loadingStore.setIsLoading(false);
        }
      });

    return result;
  };

  prorateWeeklyHours = (weeklyHour: number, frequency: number): number => {
    var result = weeklyHour;

    if (frequency === PayFrequencyEnum.Weekly.number) {
    }
    if (frequency === PayFrequencyEnum.Fortnightly.number) {
      result = result * PayFrequencyEnum.Fortnightly.number;
    }
    if (frequency === PayFrequencyEnum.FourWeekly.number) {
      result = result * PayFrequencyEnum.FourWeekly.number;
    }
    if (frequency === PayFrequencyEnum.Monthly.number) {
      result = (result * 52) / 12;
    }

    console.log(result);
    return result;
  };

  setPayrunAfterStep0 = (payrunForm: FormInstance<any>) => {
    this.currentPayrun.payFrequency = payrunForm.getFieldValue("PayFrequency");
    this.currentPayrun.payPeriodStartDate =
      payrunForm.getFieldValue("PayPeriodStartDate");
    this.currentPayrun.payPeriodEndDate =
      payrunForm.getFieldValue("PayPeriodEndDate");
    this.currentPayrun.payOnDate = payrunForm.getFieldValue("PayOnDate");
  };

  setPayrunAfterStep1_SelectEmployee = (
    newlySelectedEmployeeList: React.Key[]
  ) => {
    console.log(newlySelectedEmployeeList);

    if (this.currentPayrun !== undefined) {
      console.log("payrun setup via step 1");

      // check existing employeeToPay in the current payrun, add or remove
      this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
        (eachSelectedEmployee) => {
          // console.log(newlySelectedEmployeeList.indexOf(eachSelectedEmployee.employeeId));

          //if existing employee to pay are in the newly selected list, then keep everything
          if (
            newlySelectedEmployeeList.indexOf(eachSelectedEmployee.employeeId) >
            -1
          ) {
            eachSelectedEmployee.isRemovedFromPayrun = false;
          } else {
            //remove from the list
            eachSelectedEmployee.isRemovedFromPayrun = true;
          }
        }
      );

      //then check, if anything new from the newlySelect list, and then add if new
      newlySelectedEmployeeList.forEach((item) => {
        let tempAddThisEmployee =
          this.currentPayrun?.manyPayrunEmployeeToPayDTO.filter(
            (x) => x.employeeId === item.toString()
          );

        // does not in the list, then add it on
        if (
          tempAddThisEmployee === undefined ||
          tempAddThisEmployee.length === 0
        ) {
          let tempEmployee =
            store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.filter(
              (x) => x.id === item.toString()
            );

          if (tempEmployee !== undefined) {
            let temp: IPayrunEmployeeToPayDTO = {
              id: shortid(),
              isRemovedFromPayrun: false,
              employeeId: tempEmployee[0].id,
              firstName: tempEmployee[0].firstName,
              middleName: tempEmployee[0].middleName,
              lastName: tempEmployee[0].lastName,
              manyPayrunEmployeeToPayDetailDTO: [],
              hourlyRateSnapshot: tempEmployee[0].hourlyRate,
              irdNumberSnapshot: tempEmployee[0].irdNumber,
              taxCodeSnapshot: tempEmployee[0].taxCode,
              regularHourPerWeekSnapshot: this.prorateWeeklyHours(
                tempEmployee[0].regularHourPerWeek,
                this.currentPayrun.payFrequency
              ),
            };

            let tempNewPayDetail: IPayrunEmployeeToPayDetailDTO = {
              id: shortid(),
              irdUserEmployeeId: tempEmployee[0].id,
              isDeleted: false,
              hourlyRateSnapshot: tempEmployee[0].hourlyRate,
              payTypeId: PayTypeEnum.NormalHourlyRatePay,
              isSubjectToTax: true,
              hoursToPay: this.prorateWeeklyHours(
                tempEmployee[0].regularHourPerWeek,
                this.currentPayrun.payFrequency
              ),
            };

            temp.manyPayrunEmployeeToPayDetailDTO.push(tempNewPayDetail);
            this.currentPayrun?.manyPayrunEmployeeToPayDTO.push(temp);
          }
        }
      });

      console.log(toJS(this.currentPayrun));
      console.log(toJS(this.currentPayrun.manyPayrunEmployeeToPayDTO));

      if (this.currentPayrun.hasUpcomingLeaveDoneAndNoNeedAgain !== true) {
        this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
          (eachEmployeeToPay) => {
            if (eachEmployeeToPay.isRemovedFromPayrun === false) {
              // add all the upcoming leave
              let masterEmployee =
                store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.find(
                  (x) => x.id === eachEmployeeToPay.employeeId
                );

              if (masterEmployee !== undefined) {
                //remove leave outside payPeriod
                console.log(toJS(this.currentPayrun));

                eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO =
                  eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.filter(
                    (x) =>
                      x.payTypeId === PayTypeEnum.NormalHourlyRatePay ||
                      x.whichDateTookLeave === undefined ||
                      (moment(
                        this.currentPayrun.payPeriodStartDate,
                        "YYYY-MM-DD"
                      ).isSameOrBefore(
                        moment(x.whichDateTookLeave, "YYYY-MM-DD")
                      ) &&
                        moment(
                          this.currentPayrun.payPeriodEndDate,
                          "YYYY-MM-DD"
                        ).isSameOrAfter(
                          moment(x.whichDateTookLeave, "YYYY-MM-DD")
                        )) ||
                      x.payTypeId === PayTypeEnum.Accrual_FinalPayout ||
                      x.payTypeId === PayTypeEnum.AvailableLeave_FinalPayout ||
                      x.payTypeId === PayTypeEnum.AlternativeLeave_FinalPayout
                  );
                console.log(toJS(this.currentPayrun));

                masterEmployee.manyIRDUserEmployeeUpcomingLeaveDTO?.forEach(
                  (eachUpcomingleave) => {
                    eachUpcomingleave.manyIRDUserEmployeeUpcomingLeaveDetailDTO.forEach(
                      (eachUpcomingLeaveDetail) => {
                        if (
                          moment(
                            this.currentPayrun.payPeriodStartDate,
                            "YYYY-MM-DD"
                          ).isSameOrBefore(
                            moment(
                              eachUpcomingLeaveDetail.leaveDateTime,
                              "YYYY-MM-DD"
                            )
                          ) &&
                          moment(
                            this.currentPayrun.payPeriodEndDate,
                            "YYYY-MM-DD"
                          ).isSameOrAfter(
                            moment(
                              eachUpcomingLeaveDetail.leaveDateTime,
                              "YYYY-MM-DD"
                            )
                          )
                        ) {
                          let isAlreadyInPayrun =
                            eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.filter(
                              (x) =>
                                x.upcomingLeaveDetailId ===
                                eachUpcomingLeaveDetail.id
                            ).length > 0;

                          if (isAlreadyInPayrun === true) {
                            if (
                              eachUpcomingleave.isDeleted === true ||
                              eachUpcomingLeaveDetail.isDeleted === true
                            ) {
                              eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO
                                .filter(
                                  (x) =>
                                    x.upcomingLeaveDetailId ===
                                    eachUpcomingLeaveDetail.id
                                )
                                .forEach((item) => {
                                  item.isDeleted = true;
                                });
                            }
                          } else {
                            // include the upComing leave
                            if (
                              eachUpcomingleave.isDeleted === false &&
                              eachUpcomingLeaveDetail.isDeleted === false &&
                              eachUpcomingLeaveDetail.hasProcessed !== true
                            ) {
                              let tempNewPayDetail: IPayrunEmployeeToPayDetailDTO =
                                {
                                  id: shortid(),
                                  irdUserEmployeeId:
                                    eachEmployeeToPay.employeeId,
                                  isDeleted: false,
                                  whichDateTookLeave:
                                    eachUpcomingLeaveDetail.leaveDateTime,
                                  hoursToPay:
                                    eachUpcomingLeaveDetail.leaveHours !==
                                    undefined
                                      ? parseFloat(
                                          eachUpcomingLeaveDetail.leaveHours.toString()
                                        )
                                      : undefined,
                                  leaveDaysTaken:
                                    eachUpcomingLeaveDetail.leaveDatePercentage,
                                  payTypeId: eachUpcomingleave.leaveTypeId,
                                  hourlyRateSnapshot:
                                    eachEmployeeToPay.hourlyRateSnapshot ?? 0,
                                  isSubjectToTax: true,
                                  upcomingLeaveDetailId:
                                    eachUpcomingLeaveDetail.id,
                                };

                              eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.push(
                                tempNewPayDetail
                              );
                            }
                          }
                        }
                      }
                    );
                  }
                );
                console.log(toJS(this.currentPayrun));
              }
            }

            // add name

            store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.forEach(
              (eachEmployeeFromDB) => {
                if (eachEmployeeFromDB.id === eachEmployeeToPay.employeeId) {
                  eachEmployeeToPay.firstName = eachEmployeeFromDB.firstName;
                  eachEmployeeToPay.lastName = eachEmployeeFromDB.lastName;
                }
              }
            );
          }
        );
      }
    }

    console.log(toJS(this.currentPayrun));
  };

  setPayrunAfterStep1_AddLeaveDrawer = (
    newLeaveArray: IPayrunEmployeeToPayDetailDTO[]
  ) => {
    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
      (eachEmployeeToPay) => {
        if (
          eachEmployeeToPay.employeeId ===
          store.employeeStore.currentEmployee?.id
        ) {
          newLeaveArray.forEach((eachNew) => {
            eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.push(eachNew);
          });
        }
      }
    );
  };

  upcomingExtraPayToPayrunDetail = (
    temp: IUpcomingReimbursementOrDeductionDTO,
    isAddDuringPayrun: boolean
  ): IPayrunEmployeeToPayDetailDTO => {
    let result: IPayrunEmployeeToPayDetailDTO = {
      id: shortid(),
      irdUserEmployeeId: temp.irdUserEmployeeId,
      createdDateTime: isAddDuringPayrun
        ? moment().format("YYYY-MM-DD")
        : temp.createdDateTime,
      payTypeId: temp.payTypeId,
      isAddAlternativeLeave: temp.isAddAlternativeLeave,
      howManyAlternativeLeaveToAdd: temp.howManyAlternativeLeaveToAdd,
      description: temp.description,
      amount:
        temp.amount !== undefined
          ? parseFloat(temp.amount?.toString())
          : undefined,
      hoursToPay:
        temp.hoursToPay !== undefined
          ? parseFloat(temp.hoursToPay.toString())
          : undefined,
      note: temp.note,
      isSubjectToTax: temp.isSubjectToTax,
      isRecurringItem: temp.isRecurringItem,
      isDeleted: temp.isDeleted ?? false,
      destinationBankAccNumber: temp.destinationBankAccNumber,
      destinationBankAccName: temp.destinationBankAccName,
      receiverReference: temp.receiverReference,
      receiverCode: temp.receiverCode,
      receiverParticular: temp.receiverParticular,
      totalDeductionAmount:
        temp.totalDeductionAmount !== undefined
          ? parseFloat(temp.totalDeductionAmount.toString())
          : undefined,
      slcirRate:
        temp.slcirRate !== undefined
          ? parseFloat(temp.slcirRate.toString())
          : undefined,
      upcomingExtraPayDeductionId: temp.id,
      whichDateTookLeave: undefined,
    };
    return result;
  };

  setPayrunAfterStep2_HoursAndLeave = () => {
    if (
      this.currentPayrun !== undefined &&
      this.currentPayrun.hasUpcomingExtraPayDeductionDoneAndNoNeedAgain !== true
    ) {
      this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
        (eachEmployeeToPayFromCurrentPayRun) => {
          store.irdUserStore.currentIrdUser?.manyIRDUserEmployeeDTO.forEach(
            (eachEmployeeFromDB) => {
              if (
                eachEmployeeFromDB.id ===
                eachEmployeeToPayFromCurrentPayRun.employeeId
              ) {
                let existingDetailList: string[] = [];
                eachEmployeeToPayFromCurrentPayRun.manyPayrunEmployeeToPayDetailDTO.forEach(
                  (eachExistingPayDetail) => {
                    if (
                      eachExistingPayDetail.upcomingExtraPayDeductionId !==
                        undefined &&
                      eachExistingPayDetail.upcomingExtraPayDeductionId !== null
                    ) {
                      existingDetailList.push(
                        eachExistingPayDetail.upcomingExtraPayDeductionId
                      );
                    }
                  }
                );

                console.log(existingDetailList);
                console.log(
                  eachEmployeeFromDB.manyIRDUserEmployeeUpcomingLeaveDTO
                );

                eachEmployeeFromDB.manyUpcomingReimbursementOrDeductionDTO?.forEach(
                  (eachExtraPayDeductionFromDB) => {
                    if (
                      existingDetailList.indexOf(
                        eachExtraPayDeductionFromDB.id
                      ) < 0
                    ) {
                      let temp: IUpcomingReimbursementOrDeductionDTO = toJS(
                        eachExtraPayDeductionFromDB
                      );

                      if (temp.payTypeId === PayTypeEnum.TimeAndHalf) {
                        if (temp.hoursToPay !== undefined) {
                          temp.amount =
                            eachEmployeeFromDB.hourlyRate *
                            1.5 *
                            parseFloat(temp.hoursToPay.toString());
                        }
                      } else if (temp.payTypeId === PayTypeEnum.DoubleTime) {
                        if (temp.hoursToPay !== undefined) {
                          temp.amount =
                            eachEmployeeFromDB.hourlyRate *
                            2 *
                            parseFloat(temp.hoursToPay.toString());
                        }
                      }

                      let result: IPayrunEmployeeToPayDetailDTO =
                        this.upcomingExtraPayToPayrunDetail(temp, false);

                      eachEmployeeToPayFromCurrentPayRun.manyPayrunEmployeeToPayDetailDTO.push(
                        result
                      );
                    }
                  }
                );
              }
            }
          );
        }
      );
    }
  };

  setNormalWorkingHours = (
    tempDetailPay: IPayrunEmployeeToPayDetailDTO | undefined,
    hours: any
  ) => {
    if (tempDetailPay !== undefined) {
      this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
        (eachEmployeeToPay) => {
          eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.forEach(
            (eachDetail) => {
              if (eachDetail.id === tempDetailPay.id) {
                eachDetail.hoursToPay = hours;
              }
            }
          );
        }
      );
    }
  };

  deleteLeaveDetail = (deleteItem: IPayrunEmployeeToPayDetailDTO) => {
    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
      (eachEmployeeToPay) => {
        eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.forEach(
          (eachDetail) => {
            if (eachDetail.id === deleteItem.id) {
              eachDetail.isDeleted = true;
            }
          }
        );
      }
    );
  };

  resetCurrentPayrun = () => {
    this.currentPayrun = {
      id: shortid(),
      irdUserId: shortid(),
      isDeleted: false,
      payFrequency: 0,
      payPeriodStartDate: "",
      payPeriodEndDate: "",
      payOnDate: "",
      manyPayrunEmployeeToPayDTO: [],

      businessNameSnapshot: "",
      irdNumberSnapshot: "",
      paidType: -1,
      hasUpcomingExtraPayDeductionDoneAndNoNeedAgain: false,
      hasUpcomingLeaveDoneAndNoNeedAgain: false,
    };

    this.isFromReview = false;
    this.currentStep = 0;
    this.isDisableEdit = false;

    localStorage.removeItem("currentPayrunID");

    store.loadingStore.setIsLoading(false);
  };

  reviewAndEditHandler = async (
    item: IPayrunDTO,
    step: number,
    isDisableEdit: boolean
  ) => {
    await this.setCurrenPayrunId(item);
    this.setCurrentStep(step);
    this.setDisableEdit(isDisableEdit);
  };

  setCurrenPayrunId = async (result: IPayrunDTO) => {
    localStorage.setItem("currentPayrunID", result.id);
  };

  setCurrenPayrun_For_Review_Refresh = async (
    step1Form?: FormInstance<any>
  ) => {
    let tempPayrunID = localStorage.getItem("currentPayrunID");

    console.log(tempPayrunID);

    if (tempPayrunID !== null) {
      store.loadingStore.setIsLoading(true);
      const bodyParameters = {
        AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
        CurrentPayrunId: tempPayrunID,
      };

      let requestHeader = await store.authStore.getRequestHeader();

      console.log("get current payrun, and id " + tempPayrunID);

      await axios
        .post("/Payrun/GetCurrentPayrun", bodyParameters, requestHeader)
        .then((response) => {
          let result: IPayrunDTO = response.data;

          if (
            (result.payrunStatus === PayrunStatusEnum.Draft ||
              result.payrunStatus === PayrunStatusEnum.Approved) &&
            result.isPayrunForFinalPay !== true
          ) {
            this.setDisableEdit(false);
          } else {
            this.setDisableEdit(true);
          }
          console.log(result);

          this.currentPayrun = result;
          this.isFromReview = true;

          if (step1Form !== undefined) {
            step1Form.setFieldsValue({
              PayFrequency: result.payFrequency,
              PayPeriodStartDate: result.payPeriodStartDate,
              PayPeriodEndDate: result.payPeriodEndDate,
              PayOnDate: result.payOnDate,
            });
          }

          if (result.isPayrunForFinalPay === true) {
            this.setCurrentStep(4);
          }

          store.loadingStore.setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            store.loadingStore.setIsLoading(false);
          }
        });
    } else {
      this.setDisableEdit(false);
    }
  };

  removeExtraPayDeduction = (requestId: string) => {
    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
      (eachEmployeeToPay) => {
        eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.forEach(
          (eachPayDetail) => {
            if (eachPayDetail.id === requestId) {
              eachPayDetail.isDeleted = true;
            }
          }
        );
      }
    );
  };

  addExtraPayDeductionDuringPayrun = async (
    itemToAdd: IUpcomingReimbursementOrDeductionDTO
  ) => {
    console.log(toJS(this.currentPayrun));

    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
      (eachEmployeeToPay) => {
        if (eachEmployeeToPay.employeeId === itemToAdd.irdUserEmployeeId) {
          if (
            itemToAdd.payTypeId === PayTypeEnum.TimeAndHalf &&
            itemToAdd.hoursToPay !== undefined &&
            eachEmployeeToPay.hourlyRateSnapshot !== undefined
          ) {
            itemToAdd.amount =
              parseFloat(itemToAdd.hoursToPay.toString()) *
              1.5 *
              eachEmployeeToPay.hourlyRateSnapshot;
          } else if (
            itemToAdd.payTypeId === PayTypeEnum.DoubleTime &&
            itemToAdd.hoursToPay !== undefined &&
            eachEmployeeToPay.hourlyRateSnapshot !== undefined
          ) {
            itemToAdd.amount =
              parseFloat(itemToAdd.hoursToPay.toString()) *
              2 *
              eachEmployeeToPay.hourlyRateSnapshot;
          }

          let result: IPayrunEmployeeToPayDetailDTO =
            this.upcomingExtraPayToPayrunDetail(itemToAdd, true);

          eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.push(toJS(result));
        }
      }
    );

    console.log(toJS(this.currentPayrun));
  };

  editExtraPayDeductionDuringPayrun = async (
    requestToEdit: IUpcomingReimbursementOrDeductionDTO
  ) => {
    let itemToEdit: IUpcomingReimbursementOrDeductionDTO = toJS(requestToEdit);

    this.currentPayrun.manyPayrunEmployeeToPayDTO.forEach(
      (eachEmployeeToPay) => {
        if (eachEmployeeToPay.employeeId === itemToEdit.irdUserEmployeeId) {
          eachEmployeeToPay.manyPayrunEmployeeToPayDetailDTO.forEach(
            (eachExtrapayDeduction) => {
              if (eachExtrapayDeduction.id === itemToEdit.id) {
                eachExtrapayDeduction.payTypeId = itemToEdit.payTypeId;
                eachExtrapayDeduction.payTypeName = itemToEdit.payTypeName;
                eachExtrapayDeduction.isAddAlternativeLeave =
                  itemToEdit.isAddAlternativeLeave;
                eachExtrapayDeduction.howManyAlternativeLeaveToAdd =
                  itemToEdit.howManyAlternativeLeaveToAdd;
                eachExtrapayDeduction.description = itemToEdit.description;
                eachExtrapayDeduction.amount =
                  itemToEdit.amount !== undefined && itemToEdit.amount !== null
                    ? parseFloat(itemToEdit.amount?.toString())
                    : undefined;
                eachExtrapayDeduction.hoursToPay =
                  itemToEdit.hoursToPay !== undefined &&
                  itemToEdit.hoursToPay !== null
                    ? parseFloat(itemToEdit.hoursToPay.toString())
                    : undefined;
                eachExtrapayDeduction.note = itemToEdit.note;
                eachExtrapayDeduction.isSubjectToTax =
                  itemToEdit.isSubjectToTax;
                eachExtrapayDeduction.isRecurringItem =
                  itemToEdit.isRecurringItem;
                eachExtrapayDeduction.isDeleted = itemToEdit.isDeleted ?? false;
                eachExtrapayDeduction.destinationBankAccNumber =
                  itemToEdit.destinationBankAccNumber;
                eachExtrapayDeduction.destinationBankAccName =
                  itemToEdit.destinationBankAccName;
                eachExtrapayDeduction.receiverReference =
                  itemToEdit.receiverReference;
                eachExtrapayDeduction.totalDeductionAmount =
                  itemToEdit.totalDeductionAmount !== undefined &&
                  itemToEdit.totalDeductionAmount !== null
                    ? parseFloat(itemToEdit.totalDeductionAmount.toString())
                    : undefined;
                eachExtrapayDeduction.slcirRate =
                  itemToEdit.slcirRate !== undefined &&
                  itemToEdit.slcirRate !== null
                    ? parseFloat(itemToEdit.slcirRate.toString())
                    : undefined;
              }

              if (
                eachExtrapayDeduction.payTypeId === PayTypeEnum.TimeAndHalf &&
                eachExtrapayDeduction.hoursToPay !== undefined &&
                eachEmployeeToPay.hourlyRateSnapshot !== undefined
              ) {
                eachExtrapayDeduction.amount =
                  eachExtrapayDeduction.hoursToPay *
                  1.5 *
                  eachEmployeeToPay.hourlyRateSnapshot;
              } else if (
                eachExtrapayDeduction.payTypeId === PayTypeEnum.DoubleTime &&
                eachExtrapayDeduction.hoursToPay !== undefined &&
                eachEmployeeToPay.hourlyRateSnapshot !== undefined
              ) {
                eachExtrapayDeduction.amount =
                  eachExtrapayDeduction.hoursToPay *
                  2 *
                  eachEmployeeToPay.hourlyRateSnapshot;
              }
            }
          );
        }
      }
    );
  };
}
