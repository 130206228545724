import { makeAutoObservable } from "mobx";
import { IMasterFreeCoupon } from "../beforeLogin/1_LandingPage/Section3";
import axios from "axios";
import { IConfigPricing } from "../interface/PricingInterface";

export default class LandingPageStore {
  isShowPrivacy: boolean = false;

  isShowTermsCondition: boolean = false;

  isContactUs: boolean = false;

  landingPageMasterFreeCoupon?: IMasterFreeCoupon;

  referralMasterFreeCoupon?: IMasterFreeCoupon;

  baseFee?: IConfigPricing;

  feePerEmployeePerWeek?: IConfigPricing;

  constructor() {
    makeAutoObservable(this);
  }

  getPricing = () => {
    axios
      .get("/BeforeLogin/GetPricing")
      .then((response) => {
        console.log(response.data);

        let result: IConfigPricing[] = response.data.item1;

        this.setLandingPageCoupon(response.data.item2);

        this.setReferralCoupon(response.data.item3);

        let tempBaseFee = result.filter(
          (x) =>
            x.pricingName.toLowerCase().includes("base fee") ||
            x.pricingName.toLowerCase().includes("banking fee")
        );
        if (tempBaseFee.length > 0) {
          this.baseFee = tempBaseFee[0];
        }

        let tempFeePerEmployee = result.filter((x) =>
          x.pricingName.toLowerCase().includes("per employee per week")
        );
        if (tempFeePerEmployee.length > 0) {
          this.feePerEmployeePerWeek = tempFeePerEmployee[0];
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          console.log(error);

          Object.values(error.response.data.errors)
            .flat()
            .map((err, i) => console.log(err));
        }
      });
  };

  setLandingPageCoupon = (tempCoupon: IMasterFreeCoupon) => {
    this.landingPageMasterFreeCoupon = tempCoupon;
  };

  setReferralCoupon = (tempCoupon: IMasterFreeCoupon) => {
    this.referralMasterFreeCoupon = tempCoupon;
  };

  setIsShowPrivacy = (temp: boolean) => {
    this.isShowPrivacy = temp;
  };

  setIsShowTermsCondition = (temp: boolean) => {
    this.isShowTermsCondition = temp;
  };

  setIsContactUs = (temp: boolean) => {
    this.isContactUs = temp;
  };

  getSoftwareName = (): string => {
    let softwareName: string = "";
    if (process.env.REACT_APP_BASE_SoftwareName !== undefined) {
      softwareName = process.env.REACT_APP_BASE_SoftwareName;
    }

    return " " + softwareName;
  };
}
