import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../mobxStores/store";

export const TermsHeading: React.CSSProperties = {
  fontSize: 24,
  fontWeight: 600,
  lineHeight: "116%",
  color: "#495467",
  marginBottom: 12,
  marginTop: 24,
};

export const TermsTitle: React.CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  lineHeight: "116%",
  color: "#495467",
  marginBottom: 24,
};

export const TermsEachPoint: React.CSSProperties = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "116%",
  color: "#495467",
  marginBottom: 12,
};

export const TermsEachSubPoint: React.CSSProperties = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "125%",
  color: "#495467",
  marginBottom: 12,
  paddingLeft: 12,
};

interface ITermsCondition {
  isShow: boolean;
  closeHandler: Function;
  isModal: boolean;
}

const TermsCondition = ({ isShow, closeHandler, isModal }: ITermsCondition) => {
  const { landingPageStore } = useStore();

  let contactEmail: string = "";
  if (process.env.REACT_APP_BASE_Email !== undefined) {
    contactEmail = " " + process.env.REACT_APP_BASE_Email + " ";
  }
  let websiteLink: string = "";
  if (process.env.REACT_APP_BASE_SoftwareLink !== undefined) {
    websiteLink = process.env.REACT_APP_BASE_SoftwareLink;
  }

  const Content = (): JSX.Element => {
    return (
      <>
        <div style={{ ...TermsHeading }}>
          {landingPageStore.getSoftwareName()}'s Terms & Conditions
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          This terms & conditions is not a transfer of rights, title or interest
          between {landingPageStore.getSoftwareName()} services and you. We
          reserve the right to update or modify the terms of this agreement at
          any time without prior notice. We will use reasonable endeavours to
          provide you with notice about the update or modification of the terms
          of this agreement.
        </div>
        {/* Registration */}
        <div style={{ ...TermsHeading }}>1. Registration</div>
        <div style={{ ...TermsEachPoint }}>
          1.1 Eligibility: To be eligible to receive the Services you must
          complete our online signup form, be registered as an employer with
          Inland Revenue and provide such other information as we may request in
          order to verify your identity and proposed use of the Services. In
          order to be eligible to use our Website / Software and/or our Services
          you must be a New Zealand based, IRD registered business providing
          payroll funds from a New Zealand located bank account. If you are not
          IRD registered and/or will not be providing payroll funds from a New
          Zealand bank account, you must immediately cease to use our Website /
          Software and our Services.{" "}
        </div>
        <div style={{ ...TermsEachPoint }}>
          1.2 Information: During your registration, we may ask you for certain
          information about your business and employees. You must provide
          accurate and complete answers to these questions.
        </div>
        <div style={{ ...TermsEachPoint }}>
          1.3 Acknowledgement: By creating an online account through our Website
          / Software, you acknowledge and agree that:
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.1 you have read and understood these Terms;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.2 these Terms are binding on any use of the Services and apply to
          you from the time that you are provided with access to the Services;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.3. you grant {landingPageStore.getSoftwareName()} authority to
          link your business as your PAYE intermediary with Inland Revenue and
          submit to Inland Revenue your payroll tax obligations on your behalf
          and you will complete any form, and take any other step, required by{" "}
          {landingPageStore.getSoftwareName()} to give effect to this authority;
          and
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.4 you have the authority to act on behalf of any person or entity
          for whom you are intending to use the Services and shall be deemed to
          have agreed to these Terms on behalf of any such person or entity.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.5 Consumer Guarantees Act (CGA) and Fair Trading Act shall not
          apply.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.6 services, or website / software services provided by us, to you,
          are in trade.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.7 You and your entities are in trade.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.8 both you and us agree to contract out the CGA and Fair Trading
          Act, and you have the opportunity to seek legal advice.{" "}
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          1.3.9 it is fair and reasonable that the parties are bound by the
          provision in the agreement.
        </div>
        {/* Right of use */}
        <div style={{ ...TermsHeading }}>2. Right of use</div>
        <div style={{ ...TermsEachPoint }}>
          Subject to these Terms, {landingPageStore.getSoftwareName()} grants
          you the right to access and use the Services via the Website /
          Software through your {landingPageStore.getSoftwareName()} Account and
          in accordance with these Terms.
        </div>
        {/* Obligations*/}
        <div style={{ ...TermsHeading }}>3. Your Obligations</div>
        <div style={{ ...TermsEachPoint }}>
          3.1 To receive Services: Without limiting any other part of these
          Terms, in order to receive the Services on any occasion you must:
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.1.1 provide us on a timely basis with all the Data we reasonably
          request or require to provide the Services; and
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.1.2 transfer to our Trust Account (without deduction or set off) in
          cleared funds all amounts required to enable us to deliver the
          Services and, in particular, make payments to your employees,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.1.3 if we are not satisfied that you have fully complied with the
          requirements in this clause 3.1, we may postpone performance of the
          Services until we are satisfied that those requirements are met.
        </div>
        <div style={{ ...TermsEachPoint }}>
          3.2 General obligations: You may only use the Services and the Website
          / Software for your own lawful internal business purposes, in
          accordance with these Terms and in accordance with any other condition
          sent to you by {landingPageStore.getSoftwareName()} or posted on the
          Website / Software.
        </div>
        <div style={{ ...TermsEachPoint }}>3.3 Regarding Data, You agree:</div>
        <div style={{ ...TermsEachSubPoint }}>
          3.3.1 to provide us on a timely basis with all the Data we reasonably
          request or require to provide the Services;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.3.2 that the Data provided by you (or on your behalf) will be
          accurate, complete and not misleading and, if you become aware that
          any Data provided to us has become inaccurate, incomplete or
          misleading, you will promptly notify us;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.3.3 that we may, and you acknowledge that we will (unless otherwise
          agreed), rely on the Data provided to us as accurate, complete and not
          misleading without seeking further independent verification or
          clarification; and
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.3.4 we will not be responsible or liable if the Data which is
          provided to us or we are instructed to obtain under or in relation to
          these Terms is or becomes inaccurate, incomplete or misleading or is
          not provided to us in a timely manner.
        </div>
        <div style={{ ...TermsEachPoint }}>
          3.4 Account Owner and Account Users:
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.4.1 The Account Owner is responsible for determining who can be an
          Account User and determining each Account User’s level of access to
          the {landingPageStore.getSoftwareName()} Account.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.4.2 The Account Owner is responsible for each Account User’s use of
          the Services, and we have no responsibility or liability for the acts
          or omissions of any Account User.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          3.4.3 We will not be a party to any dispute between an Account Owner
          and an Account User for any reason.
        </div>
        {/* Pricing*/}
        <div style={{ ...TermsHeading }}>4. Price and Payment</div>
        <div style={{ ...TermsEachPoint }}>
          4.1 Fees: You agree to pay the Fees in accordance with the pricing set
          out in {websiteLink}/pricing.
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          4.1.1 At our discretion, the pricing is subject to change.
          Communication will be provided in line with clause 13.1, and
          reasonable efforts will be made to provide a notice period prior to
          implementation of any fee variation.
        </div>
        <div style={{ ...TermsEachPoint }}>
          4.2 Invoices: we are not required to provide invoice if it’s small
          amount ($50 or below), according to the Goods and Services Tax Act
          1985, section 24 (5)(a). If the amount is $50 or more, we will
          endeavor to invoice you for the Fees by the third Business Day after
          the relevant Services are provided.
        </div>
        <div style={{ ...TermsEachPoint }}>
          4.3 Overdue amounts: If you do not pay any amount due and payable
          under and in accordance with these Terms:
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          4.3.1 every amount then owed to us by you, whether due for payment or
          not, will become immediately due and payable;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          4.3.2 we may charge you interest at the rate of 2 percent per month,
          and compound on all amounts due and payable from the date they became
          due and payable until they are paid in full;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          4.3.3 if we incur any internal or external costs or disbursements
          (including legal Fees and collection commissions) in recovery of
          monies owed, we may add these costs to the amounts owed by you; and
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          4.3.4 we may suspend usage of your{" "}
          {landingPageStore.getSoftwareName()} Account in accordance with clause
          12. until outstanding debt is recovered.
        </div>
        {/* Support*/}
        <div style={{ ...TermsHeading }}>5. Support</div>
        <div style={{ ...TermsEachPoint }}>
          5.1 Problems: If you have any problems using the Services you should
          make all reasonable efforts to investigate and diagnose problems
          yourself before contacting us. If you still need help, please check
          the support provided online by us on the Website / Software or email
          us at {contactEmail}. Please note that we are entitled to charge the
          Account Owner at $300/hour if we reasonably consider the Account Owner
          and/or Account Users to have made excessive use of the support
          Services. Whether to charge you at $300/hour is at our discretion, and
          if we do decide to charge you, you agree to pay for the cost, because
          this is part of our terms and condition.
        </div>
        <div style={{ ...TermsEachPoint }}>
          5.2 Service availability: Whilst we intend that the Services should be
          available with an uptime of 24 hours a day, seven days a week, it is
          possible that on occasions the Services or Website / Software may be
          unavailable. If for any reason we have to interrupt the availability
          of Services for longer periods than we usually anticipate, we will use
          reasonable endeavours to publish in advance details of such activity
          on the Website / Software. We will have no liability to you for
          unavailability of the Services.
        </div>
        {/* Warranties*/}
        <div style={{ ...TermsHeading }}>
          6. Warranties and Acknowledgements
        </div>
        <div style={{ ...TermsEachPoint }}>
          6.1 Acknowledgement: You acknowledge, warrant and agree that:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          6.1.1 you are authorised to create the{" "}
          {landingPageStore.getSoftwareName()} Account, to access and use the
          Services and the Website / Software and to use the information and
          Data that you input into the Website / Software, including any
          information or Data input into the Website / Software by any person
          you have authorised to use the Service;
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.2 you may not use the Services or access the Website / Software on
          behalf of another person, except if you are an Authorised User of a
          {landingPageStore.getSoftwareName()} Account who is accessing the{" "}
          {landingPageStore.getSoftwareName()} Account with permission from the
          Account Owner. If you are an Authorised User, you warrant that you
          have the appropriate permissions from the Account Owner, and
          acknowledge that you must comply with these Terms,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.3 you are responsible for ensuring the information and Data you
          input into the Website / Software and provide to us in relation to the
          Services is accurate, complete and not misleading. Further, in the
          event that you ask us to enter Data or information on your behalf, we
          may do so, however, you are responsible for checking that any such
          information has been entered correctly,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.4 you are responsible for authorising any person who is given
          access to information or Data, and you agree that we have no
          obligation to provide any person access to such information or Data
          without your authorisation and may refer any requests for information
          to your address,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.5 the provision of, access to, and use of, the Services is on an
          “as is” basis and at your own risk.{" "}
          {landingPageStore.getSoftwareName()} services shall have no
          responsibility for any damage to your computer system, other property
          or loss of data that result from the download of any content,
          material, information or software,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.6 it is your sole responsibility to determine that the Services
          meet the needs of your business and are suitable for the purposes for
          which they are used,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.7 you remain solely responsible for complying with all applicable
          accounting, tax and other laws. It is your responsibility to check
          that storage of and access to your Data via the Website / Software
          will comply with laws applicable to you (including any laws requiring
          you to retain records),
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.8 we may from time to time need to amend the Services. we will use
          reasonable endeavours to provide you with advance notice of any
          intention to amend the Services, but you acknowledge that we will not
          be responsible for any failure to do so, and we will not be
          responsible for any loss that you may suffer as a result of the
          amended Services,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.9 you are responsible for inspecting the Services on delivery and
          must, within three (3) days of delivery, notify us of any alleged
          defect, shortage in quantity, errors, omissions or failure to comply
          with the description of the Services. You will provide us with a
          reasonable opportunity to inspect the Services following delivery if
          you believe the Services are defective in any way. If you fail to
          comply with this provision, the Services will be conclusively deemed
          to have been made correctly and in accordance with any agreement
          between us and you, including these Terms,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.10 we have no responsibility to any person other than the Account
          Owner and nothing in these Terms confers, or purports to confer, a
          benefit on any person other than the Account Owner, and
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.1.11 we are not responsible or liable if any Data, information or
          documents you provide are inaccurate, incomplete or misleading.
        </div>
        <div style={{ ...TermsEachPoint }}>
          6.2 No warranty: we give no warranty:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          6.2.1 that the use of the Services will be uninterrupted or error
          free. Among other things, the operation and availability of the
          systems used for accessing the Services, including public telephone
          Services, computer networks and the internet, can be unpredictable and
          may from time to time interfere with or prevent access to the
          Services. We are not in any way responsible for any such interference
          or prevention of your access or use of the Services,
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.2.2 about the Services. Without limiting the foregoing, we do not
          warrant that the Services will meet your requirements, that the
          Services and any Data used are up-to-date, or that the Services will
          be suitable for any particular purpose. To avoid doubt, all implied
          conditions or warranties are excluded to the extent permitted by law,
          including (without limitation) warranties of merchantability, fitness
          for purpose, title and non-infringement. The parties acknowledge and
          agree that the Services are provided for the purpose of your business
          and accordingly the Consumer Guarantees Act 1993 and sections 9, 12A,
          13 and 14(1) of the Fair Trading Act 1986 will not apply to these
          Terms, the Website / Software or the supply of the Service, or
        </div>
        <div style={{ ...TermsEachSubPoint }}>
          6.2.3 about the operation, performance or functions of any third party
          Services integrated into the Services.
        </div>
        {/* Authorised Person*/}
        <div style={{ ...TermsHeading }}>7. Authorised Person</div>
        <div style={{ ...TermsEachPoint }}>
          7.1 Authorised: Where a person (Authorised Person) is registering for
          the Service on behalf of the Account Owner, the Authorised Person
          warrants that:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          7.1.1 the Authorised Person has the authority from the Account Owner
          to agree to these Terms on behalf of the Account Owner and to access
          information and give instructions on the Account Owner’s behalf in
          relation to these Terms,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          7.1.2 by registering to use the Services on behalf of the Account
          Owner, the Authorised Person binds the Account Owner to the
          performance of any and all obligations (including payment obligations)
          of the Account Owner under these Terms, without limiting the
          Authorised Person’s personal obligations under these Terms,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          7.1.3 where the Authorised Person pays the Fees on behalf of the
          Account Owner, it shall not charge the Account Owner any premium or
          additional amount for the Services, and
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          7.1.4 we may rely upon and act in accordance with any instructions
          received from the Authorised Person, as if they had been made by the
          Account Owner and without checking the authority of the Authorised
          Person.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          7.2 No liability: we are not liable in respect of any act or omission
          of {landingPageStore.getSoftwareName()} in reliance on any notice or
          instructions given by the Authorised Person.
        </div>{" "}
        {/* Confidentiality, Privacy*/}
        <div style={{ ...TermsHeading }}>
          8. Confidentiality, Privacy and Data
        </div>
        <div style={{ ...TermsEachPoint }}>
          8.1 Confidentiality: Each party (the Receiving Party) will use
          reasonable endeavours to keep the other party’s (the Providing Party)
          Confidential Information confidential and secure and not disclose or
          permit disclosure of the Providing Party’s Confidential Information to
          any third party. This clause does not apply to any disclosure of
          Confidential Information:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.1 required to be disclosed by law,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.2 that {landingPageStore.getSoftwareName()} determines is
          reasonably necessary to disclose in order to effectively provide the
          Services,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.3 that the Providing Party agrees to in writing before the
          disclosure is made,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.4 is or becomes publicly available through no fault of the
          Receiving Party,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.5 is independently acquired or developed by the Receiving Party
          without breaching any of its obligations under these Terms or at law,
          and without the benefit or use of any of the Providing Party’s
          Confidential Information disclosed by the Providing Party,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.6 is in the possession of the Receiving Party without restriction
          in relation to disclosure before the date of receipt from the
          Disclosing Party, or
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.1.7 is lawfully acquired by the Receiving Party from a third party,
          provided such information is not obtained as a result of a breach by
          that third party of any confidentiality obligations owing to the
          Providing Party.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          8.2 Privacy: we maintain a privacy policy here:
          {websiteLink}/privacy that sets out each party’s obligations in
          respect of the collection, use, and disclosure of personal
          information. Acceptance of these Terms also constitutes acceptance of
          our privacy policy.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>8.3 Data: we may:</div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.3.1 use Data provided by you for analysis in aggregate form or for
          our internal training or other ordinary business purposes; and
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          8.3.2 retain, use and disclose Data provided by you for the purpose of
          complying with law
        </div>{" "}
        {/* Security*/}
        <div style={{ ...TermsHeading }}>9. Security</div>
        <div style={{ ...TermsEachPoint }}>
          9.1 Access conditions: You must ensure that all login emails required
          to access the Services are kept secure and confidential.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          9.2 When accessing and using the Services, you must:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.1 not attempt to undermine the security or integrity of our
          computing systems or networks or, where the Services are hosted by a
          third party, that third party’s computing systems and networks,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.2 not use, or misuse, the Services in any way which may impair the
          functionality of the Services or Website / Software, or other systems
          used to deliver the Services or impair the ability of any other user
          to use the Services or Website / Software,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.3 not attempt to gain unauthorised access to any materials other
          than those to which you have been given express permission to access
          or to access the computer system on which the Services are hosted,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.4 not transmit, or input into the Website / Software, any files
          that may damage any other person’s computing devices or software,
          content that may be offensive, or material or Data in violation of any
          law,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.5 not attempt to modify, copy, adapt, reproduce, disassemble,
          decompile or reverse engineer any computer programs used to deliver
          the Services or to operate the Website / Software except as permitted
          by law,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.6 ensure that each Account User is allocated to and used by only
          one person at any time; and
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          9.2.7 ensure that each Account User does not access the Services from
          more than one device at any one time.
        </div>{" "}
        {/* Intellectual Property*/}
        <div style={{ ...TermsHeading }}>10. Intellectual Property</div>
        <div style={{ ...TermsEachPoint }}>
          10.1 Existing intellectual property: Except as expressly set out in
          these Terms, nothing in these Terms will confer upon a party any
          rights, interest or title in Intellectual Property Rights in the other
          party’s materials (including any modification to that material)
          existing at the date of these Terms or which is developed
          independently of the other party’s materials.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          10.2 Your Data: we have no Intellectual Property Rights in your Data
          other than to hold and make it available for the purposes contemplated
          by these Terms or for which it was provided.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          10.3 Backup of Data: You must maintain copies of all Data inputted
          into the Services. We adhere to reasonable policies and procedures to
          prevent Data loss, but do not make any guarantees that there will be
          no loss or corruption of Data. We expressly exclude liability for any
          loss or corruption of Data, no matter how caused.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          10.4 Termination of Service: Without prejudice to any obligation we
          have at law to retain Data, following the termination of your{" "}
          {landingPageStore.getSoftwareName()} Account, we reserve the right to
          delete all your Data in the normal course of operation. You
          acknowledge and confirm that your Data cannot be recovered from us
          once it is deleted and we will have no liability for any deleted Data.
        </div>{" "}
        {/* Indemnity and Liability*/}
        <div style={{ ...TermsHeading }}>11. Indemnity and Liability</div>
        <div style={{ ...TermsEachPoint }}>
          11.1 Account Owner indemnity: To the extent permitted by law, you will
          assume entire responsibility for, and will defend, indemnify and hold
          us harmless against, all losses, liabilities, claims (including third
          party claims), damages, costs and expenses arising out of or in
          connection with:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.1 any breach by you of these Terms or your negligence,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.2 the negligence of any of your Authorised Person, Account Users,
          employees, staff, or agents,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.3 our performance of the Services in accordance with these Terms,
          including (without limitation) any action based on the instruction of
          or information provided by an Authorised Person or an Account User,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.4 our refusal to provide any person access to your information or
          Data in accordance with these Terms,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.5 us making available information or Data to any person with your
          authorisation, or
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          11.1.6 any claim by any third party that you do not have the right to
          use any Data that you have used in relation to the Services, or that
          your use of any such Data is a breach of a third party’s Intellectual
          Property Rights.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          11.2 Exclusion: To the maximum extent permitted by law, in no event
          shall {landingPageStore.getSoftwareName()} be liable or responsible to
          you (or any other person) in contract, tort (including negligence), or
          otherwise, for any direct, indirect, special, incidental,
          consequential or punitive damages or loss (including but not limited
          to loss or corruption of information, loss or corruption of Data, the
          cost of recovering such Data and any loss of business profits,
          savings, goodwill, business or anticipated business) or damage arising
          out of or in connection with any use of, or reliance on, or inability
          to use or rely on the Service or Website / Software or from any
          failure or delay by us to deliver the Services.
        </div>
        <div style={{ ...TermsEachPoint }}>
          11.3 Maximum aggregate liability: The maximum aggregate liability of
          {landingPageStore.getSoftwareName()} for any reason relating to these
          Terms, the Website / Software or the Services will be limited in
          respect of any one incident, or series of connected incidents, to the
          proper performance (or re-performance) of the Services.
        </div>
        <div style={{ ...TermsEachPoint }}>
          11.4 Exclusive remedy: If you are not satisfied with the Services or
          these Terms, your sole and exclusive remedy is to terminate these
          Terms in accordance with clause 12.
        </div>
        {/* Suspension and Termination*/}
        <div style={{ ...TermsHeading }}>12. Suspension and Termination</div>
        <div style={{ ...TermsEachPoint }}>
          12.1 Suspension of Services: {landingPageStore.getSoftwareName()} may
          suspend provision of the Services to you at any time if:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          12.1.1 it considers that any information you have provided is
          incomplete, inaccurate or misleading or if you fail to comply with any
          request for further information that{" "}
          {landingPageStore.getSoftwareName()} considers is reasonably required
          to enable it to perform the Services,
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          12.1.2 it reasonably believes that you are not, or have not been,
          complying with these Terms or any law or regulation, or
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          12.1.3 you have not paid any amount due and payable by you in
          accordance with Terms (including any outstanding payroll payment,
          debt, interest or any costs we incur in attempting to recover monies
          owed).
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          12.2 Termination: Either party may terminate the Services to which
          these Terms apply, or cancel delivery of Services at any time and for
          any reason.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          12.3 Accrued Rights: Termination of these Terms is without prejudice
          to any rights and obligations of the parties accrued up to and
          including the date of termination. On termination of these Terms you
          will:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          12.3.1 remain liable for any accrued charges and amounts (including
          Fees) which become due for payment before or after termination, and
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          12.3.2 immediately cease to use the Services and the Website /
          Software.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          12.4 Survival: Any provisions which by their nature are intended to
          survive termination, including (without limitation) clauses 4, 6, 8,
          9, 11, 12, 13, 15 and 16, survive the termination of these Terms.
        </div>{" "}
        {/* Amendments to Terms*/}
        <div style={{ ...TermsHeading }}>13. Amendments to Terms</div>
        <div style={{ ...TermsEachPoint }}>
          13.1 Amendments: we may change these Terms from time to time. The
          amended Terms will come into effect on the posting of amended Terms on
          our Website / Software (or at such later date as we may specify). we
          will make reasonable efforts to communicate any material changes to
          the Terms prior to them coming into effect.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          13.2 Must read Terms: It is your obligation to ensure that you have
          read and understood the most recent Terms available on the Website /
          Software.
        </div>{" "}
        {/*General*/}
        <div style={{ ...TermsHeading }}>14. General</div>
        <div style={{ ...TermsEachPoint }}>
          14.1 Entire agreement: These Terms, together with the{" "}
          {landingPageStore.getSoftwareName()}
          Privacy Policy and the terms of any other notices or instructions
          given to you under or in relation to these Terms, constitute the
          entire agreement between the parties and supersede all prior
          communications, representations, agreements or understandings, either
          verbal or written, between the parties with respect to the subject
          matter of these Terms.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.2 Rights of Third Parties: A person who is not a party to these
          Terms has no right to benefit under or to enforce any term of these
          Terms.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.3 Delays: Neither party will be liable for any delay in meeting, or
          failure to meet, its obligations under these Terms (other than the
          obligation to pay money) to the extent that such delay or failure is
          caused by any event outside its reasonable control (including, without
          limitation, any delay or failure caused by any act or omission of the
          other party).
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.4 Force majeure: Neither party shall be liable for any default due
          to any Act of God, war, terrorism, strike, fire, flood, storm, or any
          other event beyond the reasonable control of either party.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.5 No Assignment: You may not assign, pledge or transfer any rights,
          duties or obligations to any other person except with the previous
          written consent of {landingPageStore.getSoftwareName()}. We may
          assign, licence or sub-contract all or any part of these Terms, or our
          rights and obligations under these Terms, without your consent.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.6 Waiver: No failure or delay by any party in exercising any right,
          power or privilege under these Terms will operate as a waiver, nor
          will any single or partial exercise preclude any other or further
          exercise or the exercise of any right, power or privilege under these
          Terms. A waiver of a right, power or remedy must be in writing and
          signed by the party giving the waiver.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.7 Severability: If any provision of these Terms is, or becomes,
          unenforceable, illegal or invalid for any reason the relevant
          provisions shall be deemed to be modified to the extent necessary to
          remedy such unenforceability, illegality or invalidity or if this is
          not possible then such provisions shall be severed from these Terms,
          without affecting the enforceability, legality or validity of any
          other provision of these Terms.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.8 Governing law and jurisdiction: These Terms shall be governed and
          construed in accordance with the laws of New Zealand, and unless
          specifically stated otherwise, you and{" "}
          {landingPageStore.getSoftwareName()} submit to the non-exclusive
          jurisdiction of the Courts of New Zealand.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          14.9 Notices: All notices given by a party under these Terms must be
          in writing and delivered by e-mail or, in the case of a notice by
          {landingPageStore.getSoftwareName()}, may also be sent by making the
          notice available on its Website / Software. A notice sent by email is
          effective on transmission, provided that any communication received or
          deemed received after 5 pm or on a day which is not a business day is
          deemed not to have been received until the next business day. Notices
          to
          {landingPageStore.getSoftwareName()} must be sent to {contactEmail} or
          to any other email address notified by email to you by us. Notices
          sent to you by email will be sent to the email address which you
          provided when setting up your access to the Services.
        </div>{" "}
        {/* PAYE on hold*/}
        <div style={{ ...TermsHeading }}>15. PAYE on hold</div>
        <div style={{ ...TermsEachPoint }}>
          15.1 You understood and are aware the PAYE you paid (on pay day),
          would be paid and cleared by IRD on the 20th of the following month
          (from the day you paid).
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          15.2 You understood, and agree that{" "}
          {landingPageStore.getSoftwareName()} may make investments of this
          money, and earn interest, or any other type of returns on them.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          15.3 You agree that the returns {landingPageStore.getSoftwareName()}{" "}
          earned on this investments, will be 100% belongs to{" "}
          {landingPageStore.getSoftwareName()}. And you are not entitled to
          whatsoever the returns or interest earned might be.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          15.4 You agree, {landingPageStore.getSoftwareName()} may invest this
          PAYE money, but not limited to the bank deposit, and it’s 100% upon{" "}
          {landingPageStore.getSoftwareName()}’s discretion where{" "}
          {landingPageStore.getSoftwareName()} wants to invest this PAYE on
          hold.
        </div>{" "}
        {/* Definitions*/}
        <div style={{ ...TermsHeading }}>Definitions</div>
        <div style={{ ...TermsEachPoint }}>
          Definitions: Unless the context requires otherwise, capitalised words
          used in these Terms shall have the following meanings:
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Account Owner means the person who is registered to use the Services,
          and, where the context permits, includes any entity on whose behalf
          that person has registered to use the Service.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Account User means each person from the Account Owner’s business,
          other than the Account Owner, that uses the Services with the
          authorisation of the Account Owner from time to time.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Business Day means any day of the week other than Saturday, Sunday or
          a public holiday.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Confidential Information includes all information directly or
          indirectly made available or exchanged between the parties to these
          Terms, whether in writing, electronically or orally, including through
          use of the Services.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Data means any Data, information or documents inputted by you or with
          your authority into the Website / Software, including employee
          information.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Direct Debit Authority Form means a form supplied by us that
          authorises your bank to make direct debit payments to us.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Intellectual Property Right means any and all intellectual or
          industrial property rights and interests held, owned or lawfully used,
          anywhere in the world, whether or not registered, including, without
          limitation:
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          – patents, trademarks, service marks, copyright, registered designs,
          trade names, symbols and logos;
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          – patent applications and applications to register trademarks, service
          marks and designs; and
        </div>{" "}
        <div style={{ ...TermsEachSubPoint }}>
          – formulae, methods, workflows, plans, Data, drawings, specifications,
          characteristics, equipment, designs, inventions, discoveries,
          improvements, knowhow, experience, software products, trade secrets,
          price lists, costing and other information.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Fees means the charges (excluding any taxes and duties), if any,
          payable by you for the Services in accordance with the pricing.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Payroll Amount means the gross remuneration (including but not limited
          to salaries, wages, ACC, redundancy payments, levies, bonuses, sick
          pay, holiday pay, long service leave, superannuation contributions and
          other payments) together with payroll taxes and deductions (including
          PAYE, Student Loan payments, child support payments and debt owed to
          Inland Revenue) for those persons you tell us are engaged in your
          business.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Services means the payroll Services, and any other ancillary Services,
          provided by us to you in relation to these Terms.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Pricing means the information relating to Services, Fees and billing
          outlined here: {websiteLink}/pricing (which may be updated or amended
          by us from time to time) or as otherwise notified by us to you. For
          the avoidance of doubt any such notice will replace any prior pricing
          arrangements you may have had with us.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()}, we, us or our means{" "}
          {landingPageStore.getSoftwareName()}, as an entity, who provides the
          services to you. and all current and future global subsidiaries.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()} Account means the current{" "}
          {landingPageStore.getSoftwareName()} account the Account Owner has
          registered with {landingPageStore.getSoftwareName()}.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          Website / Software means the Internet site at the domain
          {websiteLink} or any other site operated by us.
        </div>{" "}
        <div style={{ ...TermsEachPoint }}>
          You and your means the Account Owner, and where the context permits,
          an Account User.
        </div>{" "}
      </>
    );
  };

  return isModal ? (
    <Modal
      centered={true}
      bodyStyle={{
        height: "80vh",
        overflowY: "scroll",
      }}
      style={{ backgroundColor: "#FFFFFF", paddingBottom: 0 }}
      open={isShow}
      footer={null}
      onCancel={() => closeHandler()}
    >
      {Content()}
    </Modal>
  ) : (
    Content()
  );
};

export default observer(TermsCondition);
