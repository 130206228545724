import { Spin } from "antd";

const LoadingComponent = () => {
  return (
    <Spin
      style={{
        display: "flex",
        justifyContent: "center",
        height: "25vh",
        alignItems: "center",
      }}
    />
  );
};

export default LoadingComponent;
