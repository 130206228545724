export const AdminDashboardListEnum = {
  EmailSentHistory: "Email sent history",
  UnreadSystemError: "Unread system error",
  UnreadNewbie: "Unread newbie",
  IpDuplicateCheck: "Ip duplicate check",
  WaitClearance: "Waiting clearance",
  PayEmployee: "Pay employees",
  SendPaylips: "Send payslips",
  PayEmployee_B: "Pay employees_B",
  FiletoIRD: "File to IRD",
  UploadTxt: "Upload Txt",
  RefundList: "Refund list",
  eeBankAccError: "Ee bank acc. error",
  FileFailed: "File failed",
  // PayPAYEtoHolding: "Pay To PAYE Holding",
  PayServiceFee: "Pay service fee",
  PayIRD: "Pay IRD",
  // PayIRDfromHolding_Due20thThisMonth: "Due 20th this month",
  // PayIRDfromHolding_Total: "Pay IRD from holding total",
};
