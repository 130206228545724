import { Key } from "antd/lib/table/interface";
import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import moment from "moment";
import shortid from "shortid";
import { AdminDashboardListEnum } from "../../adminLogin/Dashboard/AdminDashboardListEnum";
import { IPayrunEmployeeToPayDetailDTO } from "../../interface/UserInterface";
import { store } from "../store";
import { Dispatch, SetStateAction } from "react";

export interface IBankBulkUploadFormat {
  uploadName: string;
  date?: string;
  deductionFromWhichAccount: string;
  deductionAmount?: string;
  receiverParticular?: string;
  receiverCode?: string;
  receiverReference?: string;
  destinationAccount?: string;
  senderParticular: string;
  senderCode: string;
  senderReference: string;
  receiverName?: string;
}

export default class AdminPayNowStore {
  currentMemu: string = "Dashboard";

  payNowSelectedRowKeys: Key[] = [];

  csvData: IBankBulkUploadFormat[] = [];

  isShowPayNowConfirmation: boolean = false;

  reRender: string = shortid();

  amount: number | undefined = undefined;

  isShowUploadCSVDrawer: boolean = false;

  payNowFeedback: {
    isShow: boolean;
    message: string[];
    isSuccess?: boolean;
    isFail?: boolean;
    isInfo?: boolean;
  } = {
    isShow: false,
    message: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setPayNowFeedback = (
    tempIsShow: boolean,
    tempMessage: string[],
    tempIsSuccess?: boolean,
    tempIsFail?: boolean,
    tempIsInfo?: boolean
  ) => {
    this.payNowFeedback = {
      isShow: tempIsShow,
      isSuccess: tempIsSuccess,
      message: tempMessage,
      isFail: tempIsFail,
      isInfo: tempIsInfo,
    };
  };

  setIsShowUploadCSVDrawer = (temp: boolean) => {
    this.isShowUploadCSVDrawer = temp;
  };

  setCSVData = async (temp: IBankBulkUploadFormat[]) => {
    this.csvData = temp;
  };

  setAmount = (temp: number | undefined) => {
    this.amount = temp;
  };

  setReRender = () => {
    this.reRender = shortid();
    this.payNowSelectedRowKeys = [];
    this.csvData = [];
    this.amount = undefined;
  };

  setIsShowPayNowConfirmation = (result: boolean) => {
    this.isShowPayNowConfirmation = result;
  };

  setPayNowSelectedRowKeys = (temp: Key[]) => {
    console.log("position 1 @" + moment().format("DD MMM YYYY HH:mm:ss"));
    this.payNowSelectedRowKeys = toJS(temp);
    console.log("position 2 @" + moment().format("DD MMM YYYY HH:mm:ss"));
  };

  paymentProposingHandler = async (
    position2: string | undefined,
    selectedKey?: Key[] | undefined
  ) => {
    console.log(selectedKey);
    if (
      position2 !== undefined &&
      selectedKey !== undefined &&
      selectedKey.length > 0
    ) {
      store.loadingStore.setIsLoading(true);
      console.log("position 1 @" + moment().format("DD MMM YYYY HH:mm:ss"));

      const bodyParameters = {
        EmployeeToPayDetailIdArray: selectedKey,
        IsFromNormalPayEmployee:
          position2 === AdminDashboardListEnum.PayEmployee,
        IsFromBankAccError: position2 === AdminDashboardListEnum.eeBankAccError,
        // IsPayPAYEToHolding:
        //   position2 === AdminDashboardListEnum.PayPAYEtoHolding,
        IsPayServiceFee: position2 === AdminDashboardListEnum.PayServiceFee,
        IsPayIRD: position2 === AdminDashboardListEnum.PayIRD ? true : false,
        IsRefundList: position2 === AdminDashboardListEnum.RefundList,
      };
      let requestHeader = await store.authStore.getRequestHeader();

      axios
        .post("/Admin/PaymentProposing", bodyParameters, requestHeader)
        .then(async (response) => {
          store.loadingStore.setIsLoading(false);

          console.log(response.data);
          console.log("position 2 @" + moment().format("DD MMM YYYY HH:mm:ss"));

          let result: IPayrunEmployeeToPayDetailDTO[] = response.data.item1;
          let deductionFromWhichAccount: string = response.data.item2;
          let resultMessage: string = response.data.item3;

          if (resultMessage === "ok") {
            // no need to set error
          } else {
            store.loadingStore.setResonse(resultMessage, false);
          }

          console.log(result);

          // download as cvs
          let csvDataTemp: IBankBulkUploadFormat[] = [];

          result.forEach((element) => {
            let temp: IBankBulkUploadFormat = {
              uploadName: "Empty",
              date: moment(element.payOnDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ),
              deductionFromWhichAccount: deductionFromWhichAccount,
              deductionAmount:
                element.amount !== undefined
                  ? parseFloat(element.amount.toString()).toFixed(2)
                  : "0.00",
              receiverParticular: element.receiverParticular,
              receiverCode: element.receiverCode,
              receiverReference: element.receiverReference,
              destinationAccount: element.destinationBankAccNumber,
              senderParticular: "",
              senderCode: "",
              senderReference: "",
              receiverName: element.destinationBankAccName,
            };

            console.log(position2);

            if (position2 === AdminDashboardListEnum.PayIRD) {
              if (
                element.batchNumberPayIRD !== undefined &&
                element.batchNumberPayIRD !== null
              ) {
                temp.uploadName = element.batchNumberPayIRD;
              }
            } else {
              if (
                element.batchNumber !== undefined &&
                element.batchNumber !== null
              ) {
                temp.uploadName = element.batchNumber;
              }
            }

            csvDataTemp.push(temp);
          });

          console.log(csvDataTemp);

          await this.setCSVData(csvDataTemp);

          //show confirmation
          this.setIsShowPayNowConfirmation(true);
          store.loadingStore.setIsLoading(false);

          console.log("position 3 @" + moment().format("DD MMM YYYY HH:mm:ss"));
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            //not admin, then show login
            console.log(error);
            store.loadingStore.setIsLoading(false);
          }
        });
    }
  };

  bankUploadAmountConfirmation = async (
    isConfirm: boolean,
    isCancel: boolean,
    amount: number,
    position2: string | undefined,
    currentStep: number,
    setStep: Dispatch<SetStateAction<number>>
  ) => {
    if (position2 !== undefined) {
      store.loadingStore.setIsLoading(true);

      const bodyParameters = {
        EmployeeToPayDetailIdArray: this.payNowSelectedRowKeys,
        IsConfirm: isConfirm,
        TotalAmountConfirmed: amount,
        IsCancel: isCancel,
        IsFromNormalPayEmployee:
          position2 === AdminDashboardListEnum.PayEmployee ||
          position2 === AdminDashboardListEnum.PayEmployee_B,
        IsFromBankAccError: position2 === AdminDashboardListEnum.eeBankAccError,
        // IsPayPAYEToHolding:
        //   position2 === AdminDashboardListEnum.PayPAYEtoHolding,
        IsPayServiceFee: position2 === AdminDashboardListEnum.PayServiceFee,
        IsPayIRD: position2 === AdminDashboardListEnum.PayIRD ? true : false,
        IsRefundList: position2 === AdminDashboardListEnum.RefundList,
      };
      let requestHeader = await store.authStore.getRequestHeader();

      console.log(bodyParameters);
      axios
        .post("/Admin/PaymentConfirmorCancel", bodyParameters, requestHeader)
        .then((response) => {
          if (response.data === "ok") {
            if (isConfirm) {
              setStep(currentStep + 1);
              // this.setPayNowFeedback(
              //   true,
              //   [
              //     "1. Use Transfer function, to transfer " +
              //       amount +
              //       ", from Clearing (suffix -00) to BulkPaymentAccount (suffix-02)",
              //     "2. use this as reference: " + this.csvData[0].uploadName,
              //     "3. wait until BulkPaymentAccount (suffix-02) receive the money " +
              //       amount,
              //     "4, then use token to both auothorise and send payemnt",
              //     "5. wait BulkPaymentAccount all processed",
              //     "6. then check BulkPaymentAccount balance should be 0",
              //     "7. Finally, downlaod new csv from Clearing account and upload again to update bank balance",
              //   ],
              //   true,
              //   undefined,
              //   undefined
              // );
            } else if (isCancel) {
              this.setIsShowPayNowConfirmation(false);

              this.setPayNowFeedback(
                true,
                ["Payment cancelled"],
                undefined,
                undefined,
                true
              );
            }
          } else {
            store.loadingStore.setResonse(response.data, false);
          }
          store.loadingStore.setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            //not admin, then show login
            console.log(error);
            store.loadingStore.setIsLoading(false);
          }
        });
    }
  };
}
