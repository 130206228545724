import axios from "axios";
import { makeAutoObservable } from "mobx";

import { store } from "./store";
import { IConfigHowToLink } from "../adminLogin/Setting/SettingHowToGuide";

export default class HowToStore {
  howToList: IConfigHowToLink[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setHowToList = (tempList: IConfigHowToLink[]) => {
    this.howToList = [...tempList];
  };

  getHowToList = async () => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {};
    let requestHeader = await store.authStore.getRequestHeader();

    axios
      .post("/Admin/GetHowToLink", bodyParameters, requestHeader)
      .then((response) => {
        let result: IConfigHowToLink[] = response.data;

        this.setHowToList(result);
        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
          store.loadingStore.setIsLoading(false);
        }
      });
  };
}
